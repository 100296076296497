import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: "",
  prepareHeaders: (headers) => {
    // Get the JWT token and include it in the headers
    const token = localStorage.getItem("jwtToken");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const apiSlice = createApi({
  baseQuery,
  tagTypes: [
    "broker",
    "admin",
    "commission",
    "assetCommission",
    "crypto",
    "cryptoPairs",
    "ExchangeCredentials",
    "ib",
    "riskInfo",
    "subib",
    "support",
  ],
  endpoints: (builder) => ({}),
});
