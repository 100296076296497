import React, { useState, useEffect } from "react";
import { useGetCryptoPairsQuery } from "../../../redux/cryptoPairsApi";
import TableLoader from "../../widgets/TableLoader";
import { useChangePasswordAdminMutation } from "../../../redux/usersApi";
import toast from "react-hot-toast";
import Button from "../../widgets/Button";
import { ValidateInputFields } from "../../validations/ValidateInputFields";
import { useTranslation } from "react-i18next";
const defaultState = {
  password: { value: "", required: true },
};

export const ChangePassword = ({ editData }) => {
  const { t } = useTranslation();
  const { data: records } = useGetCryptoPairsQuery();
  const [changePassword, { isLoading }] = useChangePasswordAdminMutation();

  const [details, setDetails] = useState(defaultState);
  const [detailsError, setDetailsError] = useState({
    password: "",
  });

  const handlePermissionsTabsSubmit = async () => {
    console.log(editData, details);

    const values = Object.fromEntries(
      Object.entries(details).map(([key, { value }]) => [key, value])
    );

    for (let key in details) {
      const validationError = ValidateInputFields(
        key,
        details[key].value,
        details[key].required
      );
      setDetailsError((prevError) => ({
        ...prevError,
        [key]: validationError,
      }));
      if (validationError) return;
    }

    console.log(values, "vleussss", editData);

    const result = await changePassword({ ...values, userId: editData.id });
    toast.dismiss();
    if (result?.data?.status) {
      toast.success(t(result.data.message));
      setDetails(defaultState);
    } else {
      toast.error(t(result?.data?.message) || t("Error occurred"));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetails((prev) => {
      return { ...prev, [name]: { ...prev[name], value } };
    });

    const validationError = ValidateInputFields(
      name,
      value,
      details[name].required
    );

    setDetailsError((prevError) => {
      return { ...prevError, [name]: validationError };
    });
  };

  if (!records) {
    return <TableLoader />;
  }
  return (
    <>
      <div>
        <>
          <div className="row">
            <div className="form-group col-md-6">
              <label className="fw-bold">{t("Change Password")}*</label>
              <input
                type="password"
                className="form-control"
                placeholder={t("Enter Password")}
                name="password"
                onChange={handleChange}
                value={details.password.value}
                autoComplete="off"
              />
              <span style={{ color: "red" }}>{t(detailsError?.password)}</span>
            </div>
          </div>

          <Button
            className="btn btn1 mt-2"
            onClick={handlePermissionsTabsSubmit}
            isLoading={isLoading}
          >
            {t("Change Password")}
          </Button>
        </>
      </div>
    </>
  );
};
