import React, { useState, useMemo, useEffect } from "react";
import {
  useAddSubIbMutation,
  useUpdateSubIbMutation,
} from "../../../redux/subIbApi";
import { useGetIbsQuery } from "../../../redux/ibApi";
import { ValidateInputFields } from "../../validations/ValidateInputFields";
import Button from "../../widgets/Button";
import { handleToast } from "../../utils/handleToast";
import PhoneInput from "react-phone-input-2";
import { useGetAdminsQuery } from "../../../redux/adminApi";
import { useGetBrokersQuery } from "../../../redux/brokerApi";
import { useSelector } from "react-redux";
import {
  findIBById,
  findBrokerById,
  findAdminById,
  filterIBsByBrokerId,
  filterBrokersByAdminId,
} from "../Elements/userUtils";
import { useTranslation } from "react-i18next";

const Profile = ({ isEdit, data, isNew, refetch, onClose, getNewUser }) => {
  const { t } = useTranslation();
  const {
    user: { role: userType },
  } = useSelector((state) => state.auth);

  const [addSubIb, { isLoading: addingSubIb }] = useAddSubIbMutation();
  const [updateSubIb, { isLoading: updatingSubIb }] = useUpdateSubIbMutation();
  const { data: ibs, isSuccess: gotIbs } = useGetIbsQuery();
  const { data: brokers, isSuccess: gotBrokers } = useGetBrokersQuery(
    {},
    { skip: userType === "Broker" }
  );
  const { data: admins, isSuccess: gotAdmins } = useGetAdminsQuery(
    {},
    { skip: userType === "Admin" || userType === "Broker" }
  );
  const [currantBrokers, setCurrantBrokers] = useState([]);
  const [currantIbs, setCurrantIbs] = useState([]);
  const [subIb, setSubIb] = useState({
    name: { value: "", required: true },
    email: { value: "", required: true },
    mobile_number: { value: "", required: true },
    adminId: {
      value: "",
      required:
        userType === "Admin" ||
        userType === "Global Admin" ||
        userType === "Broker"
          ? false
          : true,
    },
    brokerId: {
      value: "",
      required: userType === "Broker" ? false : true,
    },
    IbId: {
      value: "",
      required: userType === "Broker" ? false : true,
    },
    password: { value: "", required: true },
  });
  const [subIbError, setSubIbError] = useState({
    name: "",
    email: "",
    mobile_number: "",
    adminId: "",
    brokerId: "",
    IbId: "",
    password: "",
  });
  const [selectedSegments, setSelectedSegments] = useState({
    crypto: false,
    forex: false,
    equity: false,
  });
  const [checkboxError, setCheckboxError] = useState("");

  useEffect(() => {
    if (
      !selectedSegments.crypto &&
      !selectedSegments.forex &&
      !selectedSegments.equity
    ) {
      setCheckboxError("At least one segment must be selected");
    } else {
      setCheckboxError("");
    }
  }, [selectedSegments]);

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setSelectedSegments((prev) => ({ ...prev, [name]: checked }));
  };

  useEffect(() => {
    if (data && gotIbs) {
      if (
        (userType === "Broker" && !gotAdmins) ||
        (userType === "Admin" && gotBrokers) ||
        (gotAdmins && gotBrokers)
      ) {
        handleEdit(data);
      }
    }
  }, [data, gotIbs, gotAdmins, gotBrokers]);

  useEffect(() => {
    if (userType === "Admin") {
      setCurrantBrokers(brokers);
    }
    if (userType === "Broker") {
      setCurrantIbs(ibs);
    }
  }, [userType, ibs, brokers]);

  const handleEdit = (data) => {
    console.log(data, "subib");
    const ibId = findIBById(ibs, data.refred_by_id);
    const brokerId =
      userType !== "Broker"
        ? findBrokerById(brokers, ibId?.refred_by_id)
        : null;
    const adminId =
      userType !== "Broker" && userType !== "Admin"
        ? findAdminById(admins, brokerId?.refred_by_id)
        : null;
    const brokerIbs =
      userType !== "Broker" ? filterIBsByBrokerId(ibs, brokerId?.id) : null;
    const adminBrokers =
      userType === "Admin" || userType === "Global Admin"
        ? filterBrokersByAdminId(brokers, adminId?.id)
        : null;

    setCurrantBrokers(adminBrokers || []);
    setCurrantIbs(brokerIbs || []);

    setSubIb({
      name: { value: data.name, required: true },
      email: { value: data.email, required: true },
      mobile_number: { value: `${data.mobile_number}`, required: true },
      adminId: {
        value: adminId?.id || "",
        required:
          userType === "Admin" ||
          userType === "Global Admin" ||
          userType === "Broker"
            ? false
            : true,
      },
      brokerId: {
        value: brokerId?.id || "",
        required: userType === "Broker" ? false : true,
      },
      IbId: {
        value: data.refred_by_id,
        required: userType === "Broker" ? false : true,
      },
      password: { value: "", required: false },
    });

    setSelectedSegments({
      crypto: data.is_crypto_enabled === "yes" ? true : false,
      forex: data.is_fx_enabled === "yes" ? true : false,
      equity: data.is_equity_enabled === "yes" ? true : false,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSubIb((prev) => {
      return { ...prev, [name]: { ...prev[name], value } };
    });

    const validationError = ValidateInputFields(
      name,
      value,
      subIb[name].required
    );

    if (name === "adminId") {
      const adminBrokers = filterBrokersByAdminId(brokers, value);
      setCurrantBrokers(adminBrokers);
    }

    if (name === "brokerId") {
      const brokerIbs = filterIBsByBrokerId(ibs, value);
      setCurrantIbs(brokerIbs);
    }

    setSubIbError((prevError) => {
      return { ...prevError, [name]: validationError };
    });
  };

  const selectorBodyIbs = useMemo(() => {
    return currantIbs?.map((cryptoCoin) => (
      <option value={cryptoCoin.id} key={cryptoCoin.id}>
        {cryptoCoin.name}
      </option>
    ));
  }, [currantIbs]);

  const selectorBodyAdmins = useMemo(() => {
    return admins?.map((admin) => (
      <option value={admin.id} key={admin.id}>
        {admin.name}
      </option>
    ));
  }, [admins]);

  const selectorBodyBroker = useMemo(() => {
    return currantBrokers?.map((admin) => (
      <option value={admin.id} key={admin.id}>
        {admin.name}
      </option>
    ));
  }, [currantBrokers]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !selectedSegments.crypto &&
      !selectedSegments.forex &&
      !selectedSegments.equity
    ) {
      setCheckboxError(t("At least one segment must be selected"));
      return;
    }

    const handleUpdateOrAdd = async () => {
      const values = Object.fromEntries(
        Object.entries(subIb).map(([key, { value }]) => [key, value])
      );

      for (let key in subIb) {
        const validationError = ValidateInputFields(
          key,
          subIb[key].value,
          subIb[key].required
        );
        setSubIbError((prevError) => ({
          ...prevError,
          [key]: validationError,
        }));

        if (validationError) return;
      }

      console.log(values, selectedSegments, "selectedSegments");

      const mutationResult = isEdit
        ? await updateSubIb({ id: data.id, ...values, ...selectedSegments })
        : await addSubIb({ ...values, ...selectedSegments });

      handleToast(t, mutationResult);
      if (mutationResult?.data?.status) {
        refetch();
        const tabIndex = isNew ? 1 : 0;
        getNewUser(mutationResult.data.data, tabIndex);
      }
    };

    handleUpdateOrAdd();
  };

  return (
    <div>
      <form>
        <div className="form-group">
          <label className="fw-bold">{t("Name")}*</label>
          <input
            type="text"
            className="form-control"
            placeholder={t("Enter Name")}
            name="name"
            onChange={handleChange}
            value={subIb.name.value}
          />
          <span style={{ color: "red" }}>{t(subIbError?.name)}</span>
        </div>
        <div className="row">
          {!isEdit && (
            <div className="form-group col-md-6">
              <label className="fw-bold">{t("Email")}*</label>
              <input
                type="text"
                className="form-control"
                placeholder={t("Enter Email")}
                name="email"
                onChange={handleChange}
                value={subIb.email.value}
                autoComplete="off"
              />
              <span style={{ color: "red" }}>{t(subIbError?.email)}</span>
            </div>
          )}
          <div className="form-group col-md-6">
            <label className="fw-bold">{t("Phone Number")}*</label>
            {/* <input
              type="tel"
              className="form-control col-md-6"
              placeholder={t("Enter Phone Number")}
              name="mobile_number"
              onChange={handleChange}
              value={Subib.mobile_number.value}
            /> */}
            <PhoneInput
              key={"phoneInput"}
              country={"us"}
              value={subIb.mobile_number.value}
              onChange={(phone) => {
                setSubIb((prev) => {
                  return {
                    ...prev,
                    mobile_number: { ...prev["mobile_number"], value: phone },
                  };
                });

                const validationError = ValidateInputFields(
                  "mobile_number",
                  phone,
                  subIb["mobile_number"].required
                );
                setSubIbError((prevError) => {
                  return { ...prevError, mobile_number: validationError };
                });
              }}
              className=""
              placeholder={t("Email/Mobile")}
              enableSearch={true}
              containerClass="baseclass"
              inputClass="somebaseclass"
              countryCodeEditable={false}
              name="mobile_number"
              inputProps={{
                autoFocus: true,
              }}
              // disabled={disableGetCode}
            />
            <span style={{ color: "red" }}>{t(subIbError?.mobile_number)}</span>
          </div>

          {userType === "Global Admin" && (
            <div className="form-group col-md-6">
              <label className="fw-bold">{t("Select Admin")}*</label>
              <select
                className="form-select shadow-none  "
                id="planets-selector"
                name="adminId"
                value={subIb.adminId?.value}
                onChange={handleChange}
                disabled={isEdit}
              >
                <option value="">{t("Select admin")}</option>
                {selectorBodyAdmins}
              </select>
              <span style={{ color: "red" }}>{t(subIbError?.adminId)}</span>
            </div>
          )}

          {userType !== "Broker" && (
            <div className="form-group col-md-6">
              <label className="fw-bold">{t("Select Broker")}*</label>
              <select
                className="form-select shadow-none  "
                id="planets-selector"
                name="brokerId"
                value={subIb.brokerId?.value}
                onChange={handleChange}
                disabled={isEdit}
              >
                <option value="">{t("Select broker")}</option>
                {selectorBodyBroker}
              </select>
              <span style={{ color: "red" }}>{t(subIbError?.brokerId)}</span>
            </div>
          )}

          <div className="form-group col-md-6">
            <label className="fw-bold">{t("Select Ib")}*</label>
            <select
              className="form-select shadow-none  "
              id="planets-selector"
              name="IbId"
              value={subIb.IbId?.value}
              onChange={handleChange}
              disabled={isEdit}
            >
              <option value="">{t("Select Ib")}</option>
              {selectorBodyIbs}
            </select>
            <span style={{ color: "red" }}>{t(subIbError?.IbId)}</span>
          </div>

          {!isEdit && (
            <div className="form-group col-md-6">
              <label className="fw-bold">{t("Password")}* </label>
              <input
                type="password"
                className="form-control"
                placeholder={t("Enter Password")}
                name="password"
                onChange={handleChange}
                value={subIb.password.value}
                autoComplete="off"
              />
              <span style={{ color: "red" }}>{t(subIbError?.password)}</span>
            </div>
          )}
        </div>
        <div className="form-group">
          <label className="fw-bold">{t("Select Segments")}*</label>
          <div className="row">
            <div className="col-4">
              <label>
                <input
                  type="checkbox"
                  name="crypto"
                  checked={selectedSegments.crypto}
                  onChange={handleCheckboxChange}
                />
                <span style={{ paddingLeft: "10px" }}>{t("Crypto")}</span>
              </label>
            </div>
            <div className="col-4">
              <label style={{ paddingRight: "10px" }}>
                <input
                  type="checkbox"
                  name="forex"
                  checked={selectedSegments.forex}
                  onChange={handleCheckboxChange}
                />
                <span style={{ paddingLeft: "10px" }}>{t("Fx")}</span>
              </label>
            </div>
            <div className="col-4">
              <label style={{ paddingRight: "10px" }}>
                <input
                  type="checkbox"
                  name="equity"
                  checked={selectedSegments.equity}
                  onChange={handleCheckboxChange}
                />
                <span style={{ paddingLeft: "10px" }}>{t("Equity")}</span>
              </label>
            </div>
          </div>
          {checkboxError && (
            <span style={{ color: "red" }}>{t(checkboxError)}</span>
          )}
        </div>
        <Button
          className="btn btn1"
          variant={"primary"}
          isLoading={isEdit ? updatingSubIb : addingSubIb}
          onClick={handleSubmit}
        >
          {isEdit ? t("Update") : t("Submit")}
        </Button>
      </form>
    </div>
  );
};

export default Profile;
