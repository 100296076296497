import { apiSlice } from "./api";
import {
  getExchangeCredentialsApi,
  updateExchangeCredentialsApi,
  deleteOrdersApi,
  deleteCloseOrdersFxApi,
  deleteCloseOrdersEqApi
} from "../components/constant/Api";

export const exchangeApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getExchangeCredentials: builder.query({
      query: () => ({
        url: getExchangeCredentialsApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
    // addCryptoPairs: builder.mutation({
    //   query: (post) => ({
    //     url: postCryptoPairsApi,
    //     method: "POST",
    //     body: post,
    //   }),

    //   invalidatesTags: (_) => ["cryptoPairs"],
    // }),
    updateExchangeCredentials: builder.mutation({
      query: (post) => ({
        url: updateExchangeCredentialsApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["ExchangeCredentials"],
    }),
    deleteOrder: builder.mutation({
      query: (post) => ({
        url: deleteOrdersApi,
        method: "POST",
        body: post,
      }),
    }),
    deleteCloseOrderFX: builder.mutation({
      query: (post) => ({
        url: deleteCloseOrdersFxApi,
        method: "POST",
        body: post,
      }),
    }),

    deleteCloseOrderEQ: builder.mutation({
      query: (post) => ({
        url: deleteCloseOrdersEqApi,
        method: "POST",
        body: post,
      }),
    }),

  }),
});

export const {
  useGetExchangeCredentialsQuery,
  useUpdateExchangeCredentialsMutation,
  useDeleteOrderMutation,
  useDeleteCloseOrderFXMutation,
  useDeleteCloseOrderEQMutation,
} = exchangeApi;
