import { apiSlice } from "./api";
import {
  getNotificationsApi,
  postNotificationsApi,
} from "../components/constant/Api";

export const commissionApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query({
      query: () => ({
        url: getNotificationsApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
    postNotifications: builder.mutation({
      query: (post) => ({
        url: postNotificationsApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["notifications"],
    }),
  }),
});

export const { usePostNotificationsMutation, useGetNotificationsQuery } =
  commissionApi;
