import { useMemo } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { mkConfig, generateCsv, download } from "export-to-csv"; //or use your library of choice here
import { formatDate } from "../../utils/format";
import BigNumber from "bignumber.js";
import { useTranslation } from "react-i18next";
const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});

export const SellOrdersTableFx = ({ data }) => {
  const { t } = useTranslation();
  const formatNumberIso = (number) => {
    return number.toNumber();
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: t("S. No."),
        enableEditing: false,
        size: 40,
        Cell: ({ row }) => <span>{row.index + 1}</span>,
      },
      {
        accessorKey: "user_name",
        header: t("Username"),
        size: 50,
      },
      {
        accessorKey: "email",
        header: t("Email"),
        Cell: ({ row: { original } }) => <span>{original.email || "-"}</span>,
      },
      {
        accessorKey: "mobile_number",
        header: t("Mobile No."),
        Cell: ({ row: { original } }) => (
          <span>{original.mobile_number || "-"}</span>
        ),
      },
      {
        accessorKey: "symbol",
        header: t("Symbol"),
        // Cell: ({ row: { original } }) => (
        //   <span>{`${original.first_coin_symbol}/${original.second_coin_symbol}`}</span>
        // ),
      },
      {
        accessorKey: "quantity",
        header: t("Volume"),
      },
      // {
      //   accessorKey: "updated_at",
      //   header: "Quantity",
      //   Cell: ({ row }) => <span>{formatDate(row.original.updated_at)}</span>,
      // },
      {
        // accessorKey: "refer_id",
        header: t("Price Change"),
        Cell: ({ row }) => (
          <>
            {Number(row.original.price)}
            <ArrowRightAltIcon />
            {Number(row.original.closed_price)}{" "}
          </>
        ),
      },

      {
        // accessorKey: "p&l",
        header: t("P&L"),
        Cell: ({ row: { original } }) => {
          const currentPricea =
            original.order_side === "BUY"
              ? new BigNumber(original.closed_price)
              : new BigNumber(original.closed_price);
          const orderPrice = new BigNumber(original.price);
          let priceChange;

          if (original.order_side === "BUY") {
            priceChange = currentPricea.minus(orderPrice);
          } else if (original.order_side === "SELL") {
            priceChange = orderPrice.minus(currentPricea);
          }
          let floatingProfit;
          const quantity = parseFloat(original.quantity) * parseFloat(100000);
          floatingProfit = priceChange.multipliedBy(quantity);

          return (
            <span
              style={
                floatingProfit < 0 ? { color: "#f23345" } : { color: "#089981" }
              }
            >
              {isNaN(floatingProfit) ? "0.0" : formatNumberIso(floatingProfit)}
            </span>
          );
        },
      },

      // {
      //   accessorKey: "buy_fees",
      //   header: "Buy Fees",
      // },

      // {
      //   accessorKey: "orderMethod",
      //   header: "Order Type",
      // },
      {
        accessorKey: "status",
        header: t("Status"),
        Cell: ({ row: { original } }) => <span>{original.status}</span>,
      },

      {
        accessorKey: "createdAt",
        header: t("Date"),
        enableEditing: false,
        Cell: ({ row }) => <span>{formatDate(row.original.updated_at)}</span>,
      },
    ],
    [data]
  );

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data: data ? data : [],
    // enableGrouping: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableRowSelection: true,
    columnFilterDisplayMode: "popover",
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    enableEditing: false,
    getRowId: (row) => row.id,

    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          {t("Export All")}
        </Button>

        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          //only export selected rows
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          {t("Export Selected")}
        </Button>
      </Box>
    ),
  });

  return <MaterialReactTable table={table} />;
};
