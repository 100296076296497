import React, { useState, useEffect } from "react";
import TableLoader from "../../widgets/TableLoader";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const RoleTable = ({ permissions, getPermissions, userAddType, assignFor }) => {
  const { t } = useTranslation();
  const {
    user: { role: userType },
  } = useSelector((state) => state.auth);

  const [permissionState, setPermissionState] = useState(permissions);

  useEffect(() => {
    setPermissionState(permissions);
  }, [permissions]);

  const handleModuleCheckboxChange = (moduleName, checked) => {
    const updatedPermissions = permissionState.map((module) => {
      if (module.moduleName === moduleName) {
        return {
          ...module,
          permissions: module.permissions.map((permission) => ({
            ...permission,
            read: checked ? "1" : "0",
            write: checked ? "1" : "0",
            delete: checked ? "1" : "0",
          })),
        };
      }
      return module;
    });
    getPermissions(updatedPermissions);
    setPermissionState(updatedPermissions);
  };

  const handleCheckboxChange = (moduleName, permissionName, type) => {
    const updatedPermissions = permissionState.map((module) => {
      if (module.moduleName === moduleName) {
        return {
          ...module,
          permissions: module.permissions.map((permission) => {
            if (permission.name === permissionName) {
              console.log(permission["write"], 'permission["write"]');
              const obj =
                type === "read" && permission["read"] === "1"
                  ? {
                      read: permission["read"] === "1" ? "0" : "0",
                      write: permission["write"] === "1" ? "0" : "0",
                    }
                  : type === "write" && permission["write"] === "0"
                  ? {
                      read: permission["read"] === "1" ? "1" : "1",
                      write: permission["write"] === "1" ? "1" : "1",
                    }
                  : { [type]: permission[type] === "1" ? "0" : "1" };
              return {
                ...permission,
                ...obj,
              };
            }
            return permission;
          }),
        };
      }
      return module;
    });
    getPermissions(updatedPermissions);
    setPermissionState(updatedPermissions);
  };

  function addSpacesToCamelCase(str) {
    return str.replace(/([a-z])([A-Z])/g, "$1 $2");
  }

  return (
    <>
      {permissionState?.length === 0 ? (
        <TableLoader />
      ) : (
        <table className="table ">
          <thead className="stick-table">
            <tr>
              <th>{t('Permissions')}*</th>
              <th>{t('Read')}</th>
              <th>{t('Write')}</th>
              <th>{t('Delete')}</th>
            </tr>
          </thead>
          <tbody>
            {permissionState.map((module) =>
              (userAddType === "broker" && module.moduleName === "broker") ||
              (userAddType === "broker" && module.moduleName === "admin") ||
              (userType === "Admin" && module.moduleName === "admin") ? (
                ""
              ) : (
                <React.Fragment key={module.moduleName}>
                  <tr>
                    <th colSpan="4" className="bg-light">
                      {/* {module.label} */}
                      {t(addSpacesToCamelCase(module.label))}
                    </th>
                  </tr>
                  <tr>
                    {!assignFor && (
                      <td>
                        <input
                          id={module.moduleName}
                          type="checkbox"
                          onChange={(e) =>
                            handleModuleCheckboxChange(
                              module.moduleName,
                              e.target.checked
                            )
                          }
                        />{" "}
                        <label htmlFor={module.moduleName}>{t('Select All')}</label>
                      </td>
                    )}
                  </tr>
                  {module.permissions.map((permission) => (
                    <tr key={permission.name}>
                      <td className="text-capitalize">
                        {t(addSpacesToCamelCase(permission.name))}
                      </td>

                      <td>
                        <input
                          type="checkbox"
                          checked={permission.read === "1"}
                          disabled={assignFor && permission.read === "-1"}
                          onChange={() =>
                            handleCheckboxChange(
                              module.moduleName,
                              permission.name,
                              "read"
                            )
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          checked={permission?.write === "1"}
                          disabled={assignFor && permission?.write === "-1"}
                          onChange={() =>
                            handleCheckboxChange(
                              module.moduleName,
                              permission.name,
                              "write"
                            )
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          checked={permission.delete === "1"}
                          disabled={assignFor && permission.delete === "-1"}
                          onChange={() =>
                            handleCheckboxChange(
                              module.moduleName,
                              permission.name,
                              "delete"
                            )
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              )
            )}
          </tbody>
        </table>
      )}
    </>
  );
};

export default RoleTable;
