import React, { useState } from "react";
import TableLoader from "../../widgets/TableLoader";
import FxTable from "./FxTable";
import { ValidateInputFields } from "../../validations/ValidateInputFields";
import { handleToast } from "../../utils/handleToast";
import Button from "../../widgets/Button";
import CustomModal from "../../widgets/Modal";
import {
  useAddDataProviderMutation,
  useGetDataProviderQuery,
  useDeleteDataProviderMutation,
  useDisableDataProviderMutation,
  useUpdateDataProviderMutation,
} from "../../../redux/dataProviderApi";
import { useTranslation } from "react-i18next";

const Fx = () => {
  const { t } = useTranslation();
  const { data: records, isLoading, refetch } = useGetDataProviderQuery();
  const [addDataProvider, { isLoading: addingDataProvider }] =
    useAddDataProviderMutation();
  const [deleteDataProvider, { isLoading: deletingDataProvider }] =
    useDeleteDataProviderMutation();

  const [disableDataProvider, { isLoading: disablingDataProvider }] =
    useDisableDataProviderMutation();

  const [updateDataProvider, { isLoading: updatingDataProvider }] =
    useUpdateDataProviderMutation();

  const [editData, setEditData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [isDisable, setIsDisable] = useState(false);
  const [disableData, setDisableData] = useState(null);
  const [disabledStatus, setDisabledStatus] = useState(0);

  const [show, setShow] = useState(false);
  const [dataProvider, setDataProvider] = useState({
    name: { value: "", required: true },
    secret_key: { value: "", required: true },
    api_key: { value: "", required: true },
  });
  const [dataProviderError, setDataProviderError] = useState({
    name: "",
    secret_key: "",
    api_key: "",
  });

  const handleClose = () => {
    setDataProvider({
      name: { value: "", required: true },
      secret_key: { value: "", required: true },
      api_key: { value: "", required: true },
    });
    setDataProviderError(null);
    setEditData(null);
    setIsEdit(false);
    setShow(false);
    setIsDelete(false);
    setIsDisable(false);
  };
  const handleShow = () => setShow(true);

  const handleEdit = (data) => {
    setEditData(data);
    setIsEdit(true);
    setDataProvider({
      name: { value: data.name, required: true },
      secret_key: { value: data.secret_key, required: true },
      api_key: { value: data.api_key, required: true },
    });
    handleShow();
  };

  const handleDelete = (data) => {
    setDeleteData(data);
    setIsDelete(true);
    handleShow();
  };

  const handleDisable = (data) => {
    setDisableData(data);
    setDisabledStatus(data.is_disabled);
    setIsDisable(true);
    handleShow();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDataProvider((prev) => {
      return { ...prev, [name]: { ...prev[name], value } };
    });

    const validationError = ValidateInputFields(
      name,
      value,
      dataProvider[name].required
    );

    setDataProviderError((prevError) => {
      return { ...prevError, [name]: validationError };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const handleDelete = async () => {
      const { id } = deleteData;
      const result = await deleteDataProvider({ id });

      handleToast(t,result);
      if (result?.data?.status) {
        refetch();
        handleClose();
      }
    };

    const handleDisable = async () => {
      const { id, is_disabled } = disableData;
      const result = await disableDataProvider({ id, is_disabled });

      handleToast(t,result);
      if (result?.data?.status) {
        refetch();
        handleClose();
      }
    };

    const handleUpdateOrAdd = async () => {
      const values = Object.fromEntries(
        Object.entries(dataProvider).map(([key, { value }]) => [key, value])
      );

      for (let key in dataProvider) {
        const validationError = ValidateInputFields(
          key,
          dataProvider[key].value,
          dataProvider[key].required
        );
        setDataProviderError((prevError) => ({
          ...prevError,
          [key]: validationError,
        }));
        if (validationError) return;
      }

      const mutationResult = isEdit
        ? await updateDataProvider({ id: editData.id, ...values })
        : await addDataProvider(values);

      handleToast(t,mutationResult);
      if (mutationResult?.data?.status) {
        refetch();
        handleClose();
      }
    };

    isDelete
      ? handleDelete()
      : isDisable
      ? handleDisable()
      : handleUpdateOrAdd();
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="d-md-flex mb-3">
            <h3 className="page-title">{t("Fx")}</h3>
            <div className=" ms-auto">
              <button
                disabled={addingDataProvider}
                className="btn btn1"
                onClick={handleShow}
              >
                {t("Add Fx")}
              </button>
            </div>
          </div>

          {isLoading ? (
            <TableLoader />
          ) : (
            <div className="white-box">
              <div className="table-responsive">
                <FxTable
                  data={records}
                  onEdit={handleEdit}
                  onDelete={handleDelete}
                  onDisable={handleDisable}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <CustomModal
        isOpen={show}
        onClose={handleClose}
        title={
          isDelete
            ? t("Delete Data Provider")
            : isDisable && disabledStatus === "0"
            ? t("Enable Data Provider")
            : isDisable && disabledStatus === "1"
            ? t("Disable Data Provider")
            : isEdit
            ? t("Edit Data Provider")
            : t("Add Data Provider")
        }
        footer={
          <Button
            className="btn btn1"
            variant={isDelete ? t("danger") : t("primary")}
            isLoading={
              isDelete
                ? deletingDataProvider
                : isDisable
                ? disablingDataProvider
                : isEdit
                ? updatingDataProvider
                : addingDataProvider
            }
            onClick={handleSubmit}
          >
              {isDelete
              ? t("Delete")
              : isDisable && disabledStatus === "0"
              ? t("Enable")
              : isDisable && disabledStatus === "1"
              ? t("Disable")
              : isEdit
              ? t("Update")
              : t("Submit")}
          </Button>
        }
      >
        {isDelete ? (
          <div>{t("Are you sure you want to delete?")}</div>
        ) : isDisable ? (
          <div>{t(`Are you sure you want to ${
            disabledStatus === "0" ? "enable" : "disable"
          }?`)}</div>
        ) : (
          <form>
            <div className="form-group">
              <label className="fw-bold">{t('Name')}</label>
              <input
                type="text"
                className="form-control"
                placeholder={t("Enter Name")}
                name="name"
                onChange={handleChange}
                value={dataProvider.name.value}
              />
              <span style={{ color: "red" }}>{t(dataProviderError?.name)}</span>
            </div>
            <div className="form-group">
              <label className="fw-bold">{t('Secret Key')}</label>
              <input
                type="text"
                className="form-control"
                placeholder={t("Enter Secret Key")}
                name="secret_key"
                onChange={handleChange}
                value={dataProvider.secret_key.value}
                disabled={isEdit}
              />
              <span style={{ color: "red" }}>
                {t(dataProviderError?.secret_key)}
              </span>
            </div>

            <div className="form-group">
              <label className="fw-bold">{t("API Key")}</label>
              <input
                type="text"
                className="form-control"
                placeholder={t("Enter API Key")}
                name="api_key"
                onChange={handleChange}
                value={dataProvider.api_key.value}
                disabled={isEdit}
              />
              <span style={{ color: "red" }}>{t(dataProviderError?.api_key)}</span>
            </div>
          </form>
        )}
      </CustomModal>
    </div>
  );
};

export default Fx;
