import { apiSlice } from "./api";
import {
  getAllRequestApi,
  getRequestInfoApi,
  postRequestChatsApi,
  replyAdminApi,
  userRequestStatusApi,
} from "../components/constant/Api";

export const ibApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    userRequestStatus: builder.mutation({
      query: (post) => ({
        url: userRequestStatusApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["support"],
    }),
    replyAdmin: builder.mutation({
      query: (post) => ({
        url: replyAdminApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["support"],
    }),
    getAllRequests: builder.query({
      query: () => ({
        url: getAllRequestApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
    getUserRequestInfo: builder.query({
      query: ({ ticketId }) => ({
        url: `${getRequestInfoApi}/${ticketId}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
    getUserRequestChats: builder.query({
      query: ({ ticketId }) => ({
        url: `${postRequestChatsApi}/${ticketId}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
  }),
});

export const {
  useGetAllRequestsQuery,
  useGetUserRequestInfoQuery,
  useGetUserRequestChatsQuery,
  useReplyAdminMutation,
  useUserRequestStatusMutation,
} = ibApi;
