import toast from "react-hot-toast";

export const handleToast = (t, result) => {
  toast.dismiss();

  if (result?.error?.status === 403) {
    toast.error(t(result?.error.data?.message));
    return;
  }

  if (result?.data?.status) {
    toast.success(t(result.data.message));
  } else {
    toast.error(t(result?.data?.message || "Error occurred"));
  }
};
