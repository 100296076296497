// UserList.jsx
import React, { useState } from "react";
import CustomModal from "../../widgets/Modal";
import { useGetBrokersQuery } from "../../../redux/brokerApi";
import TableLoader from "../../widgets/TableLoader";
import Profile from "./Profile";
import {
  UserPopupTabs,
  UsersTable,
  HeadWrapper,
  UserStatus,
} from "../Elements";
import { useSelector } from "react-redux";
import PageLoader from "../../widgets/PageLoader";
import { useTranslation } from "react-i18next";

const BrokerList = () => {
  const { t } = useTranslation();

  const {
    permissions: { broker },
  } = useSelector((state) => state.auth);
  const [editData, setEditData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const { data: records, isLoading, refetch } = useGetBrokersQuery();
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [isStatus, setIsStatus] = useState(false);
  const [statusData, setStatusData] = useState(null);
  const [isNew, setIsNew] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const handleAddClick = () => {
    setShowAddPopup(true);
    setIsNew(true);
  };

  const handlePopupClose = () => {
    setShowAddPopup(false);
    setStatusData(null);
    setEditData(null);
    setIsEdit(false);
    setIsStatus(false);
    setIsNew(false);
    setTabIndex(0);
  };

  const handleEdit = (data, tabIndex) => {
    setEditData(data);
    setIsEdit(true);
    setShowAddPopup(true);
    setIsNew(false);

    if (tabIndex) {
      setTabIndex(tabIndex);
    }
  };

  const handleStatus = (data) => {
    setStatusData(data);
    setIsStatus(true);
    setShowAddPopup(true);
  };

  if (!broker?.permissions) {
    return <PageLoader />;
  }

  return (
    <HeadWrapper
      title={t("Brokers")}
      button={
        broker?.permissions?.status?.write && (
          <button
            // disabled={addingAdmin}
            className="btn btn1"
            onClick={handleAddClick}
          >
            {t("Add Broker")}
          </button>
        )
      }
    >
      {isLoading ? (
        <TableLoader />
      ) : (
        <div className="white-box">
          <div className="table-responsive">
            <UsersTable
              data={records}
              onEdit={handleEdit}
              onStatus={handleStatus}
              access={broker?.permissions.status}
            />
          </div>
        </div>
      )}

      {isStatus && showAddPopup && (
        <UserStatus
          id={statusData.id}
          onClose={handlePopupClose}
          show={isStatus}
          refetch={refetch}
          status={statusData.user_lock}
        />
      )}

      {isNew && showAddPopup && (
        <CustomModal
          isOpen={showAddPopup}
          onClose={handlePopupClose}
          maxWidth="1000px"
          title={t("Add Broker")}
        >
          <UserPopupTabs
            isNew={isNew}
            // editData={editData}
            onClose={handlePopupClose}
            refetch={refetch}
            getNewUser={handleEdit}
            Profile={Profile}
            tabIndex={tabIndex}
            handleTabIndex={setTabIndex}
          />
        </CustomModal>
      )}

      {isEdit && showAddPopup && (
        <CustomModal
          isOpen={showAddPopup}
          onClose={handlePopupClose}
          maxWidth="1000px"
          title={t("Edit Broker")}
        >
          <UserPopupTabs
            isEdit={isEdit}
            editData={editData}
            refetch={refetch}
            onClose={handlePopupClose}
            getNewUser={handleEdit}
            Profile={Profile}
            tabIndex={tabIndex}
            handleTabIndex={setTabIndex}
          />
        </CustomModal>
      )}
    </HeadWrapper>
  );
};

export default BrokerList;
