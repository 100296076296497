const cryptoManagementArray = [
  {
    title: "adminManagement",
    label: "Admin Management",
    moduleName: "admin",
    permissions: [{ name: "status", read: "0", write: "0", delete: "0" }],
  },
  {
    title: "liveOrders",
    label: "Live Orders",
    moduleName: "liveOrders",
    permissions: [
      { name: "crypto", read: "0", write: "0", delete: "0" },
      { name: "fx", read: "0", write: "0", delete: "0" },
      { name: "equity", read: "0", write: "0", delete: "0" },
    ],
  },
  {
    title: "cryptoManagement",
    label: "Crypto Management",
    moduleName: "crypto",
    permissions: [
      { name: "coins", read: "0", write: "0", delete: "0" },
      { name: "pairs", read: "0", write: "0", delete: "0" },
      { name: "exchangeManagement", read: "0", write: "0", delete: "0" },
      { name: "tradeFee", read: "0", write: "0", delete: "0" },
      { name: "risk", read: "0", write: "0", delete: "0" },
      { name: "spread", read: "0", write: "0", delete: "0" },
      { name: "urlManagement", read: "0", write: "0", delete: "0" },
    ],
  },
  {
    title: "brokerManagement",
    label: "Broker Management",
    moduleName: "broker",
    permissions: [{ name: "status", read: "0", write: "0", delete: "0" }],
  },
  {
    title: "ibManagement",
    label: "IB Management",
    moduleName: "ib",
    permissions: [
      { name: "ib", read: "0", write: "0", delete: "0" },
      { name: "subIb", read: "0", write: "0", delete: "0" },
    ],
  },
  {
    title: "kycManagement",
    label: "KYC Management",
    moduleName: "kyc",
    permissions: [
      { name: "pendingKyc", read: "0", write: "0", delete: "0" },
      { name: "completedKyc", read: "0", write: "0", delete: "0" },
      { name: "rejectedKyc", read: "0", write: "0", delete: "0" },
    ],
  },
  {
    title: "vendorManagement",
    label: "Vendor Management",
    moduleName: "vendor",
    permissions: [
      { name: "fxVendor", read: "0", write: "0", delete: "0" },
      { name: "clearingManagement", read: "0", write: "0", delete: "0" },
      { name: "vendorKycManagement", read: "0", write: "0", delete: "0" },
    ],
  },
  {
    title: "fxManagement",
    label: "FX Management",
    moduleName: "fx",
    permissions: [
      { name: "listingCurrency", read: "0", write: "0", delete: "0" },
      { name: "commission", read: "0", write: "0", delete: "0" },
      { name: "riskManagement", read: "0", write: "0", delete: "0" },
      { name: "leverageManagement", read: "0", write: "0", delete: "0" },
      { name: "spread", read: "0", write: "0", delete: "0" },
    ],
  },
  {
    title: "equityManagement",
    label: "Equity Management",
    moduleName: "equity",
    permissions: [
      { name: "listingStocks", read: "0", write: "0", delete: "0" },
      { name: "commission", read: "0", write: "0", delete: "0" },
      { name: "riskManagement", read: "0", write: "0", delete: "0" },
    ],
  },
  {
    title: "transactionReport",
    label: "Transaction Report",
    moduleName: "transactions",
    permissions: [
      { name: "crypto", read: "0", write: "0", delete: "0" },
      { name: "fx", read: "0", write: "0", delete: "0" },
      { name: "equity", read: "0", write: "0", delete: "0" },
    ],
  },
  {
    title: "commissionReport",
    label: "Commission Report",
    moduleName: "commission",
    permissions: [
      { name: "crypto", read: "0", write: "0", delete: "0" },
      { name: "fx", read: "0", write: "0", delete: "0" },
      { name: "equity", read: "0", write: "0", delete: "0" },
    ],
  },
  {
    title: "marginTradeFx",
    label: "Margin Trade FX",
    moduleName: "margin",
    permissions: [{ name: "status", read: "0", write: "0", delete: "0" }],
  },
  {
    title: "cashActivity",
    label: "Cash Activity",
    moduleName: "cashActivity",
    permissions: [
      { name: "crypto", read: "0", write: "0", delete: "0" },
      { name: "fx", read: "0", write: "0", delete: "0" },
      { name: "equity", read: "0", write: "0", delete: "0" },
    ],
  },
  {
    title: "positionManagement",
    label: "Position Management",
    moduleName: "position",
    permissions: [
      { name: "fx", read: "0", write: "0", delete: "0" },
      { name: "equity", read: "0", write: "0", delete: "0" },
    ],
  },
  {
    title: "supportManagement",
    label: "Support Management",
    moduleName: "support",
    permissions: [{ name: "status", read: "0", write: "0", delete: "0" }],
  },
];

export default cryptoManagementArray;
