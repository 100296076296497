import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./api";
import authReducer from "./authSlice";
import { myReducer } from "./reducer";
import { isRejectedWithValue } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

export const rtkQueryErrorLogger = (api) => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    console.warn("We got a rejected action!", action);
    if (action.payload.status !== 403) {
      return next(action);
    }
    // toast.error(action.payload?.data?.message || "Error occurred");

    setTimeout(() => {
      // window.location = "/dashboard";
    }, 1000);
    return;
  }

  return next(action);
};

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    [myReducer.name]: myReducer.reducer,
    auth: authReducer,
  },

  middleware: (defaultMiddleware) =>
    defaultMiddleware().concat(apiSlice.middleware, rtkQueryErrorLogger),
  devTools: true,
});
