import { Fragment, useMemo } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv, download } from "export-to-csv"; //or use your library of choice here
import { formatDate } from "../../utils/format";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { formatDecimal } from "../../../util/decimalFormatter";
import BigNumber from "bignumber.js";

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});

export const EquityPositions = ({ data }) => {
  const { t } = useTranslation();
  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: t("S. No."),
        enableEditing: false,
        size: 40,
        Cell: ({ row }) => <span>{row.index + 1}</span>,
      },

      {
        accessorKey: "symbol",
        header: t("Symbol"),
        Cell: ({ row: { original } }) => <span>{`${original.symbol}`}</span>,
      },

      {
        accessorKey: "order_side",
        header: t("Direction"),
        Cell: ({ row: { original } }) => (
          <span
            className="ng-binding ng-scope "
            style={
              original.order_side === "BUY"
                ? { color: "rgb(0, 188, 139)" }
                : { color: "#eb6a61" }
            }
          >
            {original.order_side}
          </span>
        ),
      },

      {
        accessorKey: "quantity",
        header: t("Volume, shares"),
        Cell: ({ row: { original } }) => (
          <span>
            {original.status === "open"
              ? original.order_side === "SELL"
                ? `${original.buy_quantity}/${original.sell_quantity}`
                : `${original.sell_quantity}/${original.buy_quantity}`
              : original.order_side === "SELL"
              ? original.sell_quantity
              : original.buy_quantity}
          </span>
        ),
      },

      {
        accessorKey: "created_at",
        header: t("Open time"),

        Cell: ({ row: { original } }) => (
          <span>
            {moment(original.created_at).format("MMM D, YYYY h:mm:ss A")}
          </span>
        ),
      },

      {
        accessorKey: "updated_at",
        header: t("Close time"),
        Cell: ({ row: { original } }) => (
          <span>
            {moment(original.updated_at).format("MMM D, YYYY h:mm:ss A")}
          </span>
        ),
      },

      {
        accessorKey: "price",
        header: t("Price Change"),
        Cell: ({ row: { original } }) => (
          <span>
            {original.price} <i className="bi bi-arrow-right"></i>{" "}
            {original.closed_price}
          </span>
        ),
      },

      {
        accessorKey: "commission",
        header: t("Commission"),
        Cell: ({ row: { original } }) => (
          <span>
            {original.avgCommission
              ? "$" + parseFloat(original.avgCommission).toFixed(2)
              : "-"}
          </span>
        ),
      },

      {
        accessorKey: "per_price",
        header: t("P & L"),
        Cell: ({ row }) => {
          const item = row.original; // Access the item object from the row

          const currentPricea = new BigNumber(item.closed_price);
          const orderPrice = new BigNumber(item.price);
          let priceChange;
          if (item.order_side === "BUY") {
            priceChange = currentPricea.minus(orderPrice);
          } else if (item.order_side === "SELL") {
            priceChange = orderPrice.minus(currentPricea);
          }
          let floatingProfit;

          if (item.symbol_type === "forex") {
            const quantity = parseFloat(item.quantity) * parseFloat(100000);
            floatingProfit = priceChange?.multipliedBy(quantity);
          } else if (item.symbol_type === "equity") {
            const qty =
              item.order_side === "SELL"
                ? item.buy_quantity
                : item.sell_quantity;
            const quantity = parseFloat(qty);

            floatingProfit = priceChange?.multipliedBy(quantity);
          }

          const grossProfitLoss = floatingProfit;

          // Calculate P&L with commission deducted
          const commission = item.avgCommission
            ? new BigNumber(item.avgCommission)
            : new BigNumber(0);
          const netProfitLoss = grossProfitLoss.minus(commission);

          return (
            <Fragment>
              <span
                style={
                  floatingProfit < 0
                    ? { color: "#f23345" }
                    : { color: "#089981" }
                }
              >
                $
                {isNaN(netProfitLoss) ? "0.0" : formatDecimal(netProfitLoss, 5)}
              </span>
            </Fragment>
          );
        },
      },
    ],
    [data]
  );

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data: data ? data : [],
    // enableGrouping: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableRowSelection: true,
    columnFilterDisplayMode: "popover",
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    enableEditing: false,
    getRowId: (row) => row.id,

    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          {t("Export All")}
        </Button>

        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          //only export selected rows
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          {t("Export Selected")}
        </Button>
      </Box>
    ),
  });

  return <MaterialReactTable table={table} />;
};
