import * as React from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";


export default function NotificationsDrawer({ data }) {
  const { t } = useTranslation();

  return (
    <Card variant="outlined" sx={{ maxWidth: 360 }}>
      <Box sx={{ p: 2 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography gutterBottom variant="h5" component="div">
          {t('Notifications')}
          </Typography>
        </Stack>
      </Box>
      <Divider />
      <Box sx={{ p: 0 }}>{data}</Box>
    </Card>
  );
}
