import CryptoJS from "crypto-js";

const secretKey = process.env.REACT_APP_permissionKey;

export function decryptData(encryptedData) {
  try {
    const decrypted = CryptoJS.AES.decrypt(encryptedData, secretKey);
    const str = decrypted.toString(CryptoJS.enc.Utf8);

    if (str !== "") {
      return JSON.parse(str);
    }
    return;
  } catch (error) {
    return;
  }
}
