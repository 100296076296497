export const findIBById = (ibs, id) => {
  return ibs?.find((broker) => Number(broker.id) === Number(id));
};

export const findBrokerById = (brokers, id) => {
  return brokers?.find((broker) => Number(broker.id) === Number(id));
};

export const findAdminById = (admins, id) => {
  return admins?.find((admin) => Number(admin.id) === Number(id));
};

export const filterIBsByBrokerId = (ibs, brokerId) => {
  return ibs.filter(
    (broker) => Number(broker.refred_by_id) === Number(brokerId)
  );
};

export const filterBrokersByAdminId = (brokers, adminId) => {
  return brokers.filter(
    (broker) => Number(broker.refred_by_id) === Number(adminId)
  );
};
