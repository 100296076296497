import React, { useEffect, useState } from "react";
import Button from "../../widgets/Button";
import { handleToast } from "../../utils/handleToast";
import {
  useAddWidgetPermissionsMutation,
  useGetWidgetPermissionsQuery,
  useUpdateWidgetPermissionsMutation,
} from "../../../redux/widgetPermissionsApi";
import TableLoader from "../../widgets/TableLoader";
import { useTranslation } from "react-i18next";

const defaultState = {
  chart: { value: "1", required: false, label: "Trading Chart" },
  pairs: { value: "1", required: false, label: "Crypto Pairs" },
  orders: {
    value: "1",
    required: false,
    label: "Order List",
  },
  wallet: {
    value: "1",
    required: false,
    label: "Wallet",
  },
  marketTrades: {
    value: "1",
    required: false,
    label: "Market Trades",
  },
  marketDepth: { value: "1", required: false, label: "Market Depth" },
  buySellBox: { value: "1", required: false, label: "Buy Sell Trade Box" },
};

const defaultErrorState = {
  chart: "",
  pairs: "",
  orders: "",
  wallet: "",
  marketTrades: "",
  marketDepth: "",
  buySellBox: "",
};

const Crypto = ({ targetUserId, isEdit, isNew, refetch, onClose }) => {
  const { t } = useTranslation();
  const [cryptoPermissions, setCryptoPermissions] = useState(defaultState);
  const [cryptoPermissionsError, setCryptoPermissionsError] =
    useState(defaultErrorState);
  const [addWidgetPermissions, { isLoading: addingWidgetPermissions }] =
    useAddWidgetPermissionsMutation();
  const [updateWidgetPermissions, { isLoading: updatingWidgetPermissions }] =
    useUpdateWidgetPermissionsMutation();
  const {
    data,
    isLoading,
    refetch: refetchWidgetPermissions,
  } = useGetWidgetPermissionsQuery({
    userId: targetUserId,
    type: "crypto",
  });

  useEffect(() => {
    if (data && data.length > 0) {
      const updatedStockRiskInfo = Object.keys(defaultState).reduce(
        (acc, key) => {
          acc[key] = {
            ...defaultState[key],
            value: `${
              data[0][key.replace(/([a-z])([A-Z])/g, "$1_$2").toLowerCase()]
            }`,
          };
          return acc;
        },
        {}
      );
      console.log(updatedStockRiskInfo, "updatedStockRiskInfo");
      setCryptoPermissions(updatedStockRiskInfo);
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCryptoPermissions((prev) => {
      return { ...prev, [name]: { ...prev[name], value } };
    });

    // No validation needed for radio buttons, but you can add it if necessary
    setCryptoPermissionsError((prevError) => ({
      ...prevError,
      [name]: "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const handleUpdateOrAdd = async () => {
      const values = Object.fromEntries(
        Object.entries(cryptoPermissions).map(([key, { value }]) => [
          key,
          value,
        ])
      );

      const body = { userId: targetUserId, type: "crypto", ...values };

      console.log(body, "body");

      const mutationResult =
        data && data.length > 0
          ? await updateWidgetPermissions(body)
          : await addWidgetPermissions(body);
      handleToast(t, mutationResult);
      if (mutationResult?.data?.status) {
        refetch();
        refetchWidgetPermissions();
      }
    };

    handleUpdateOrAdd();
  };

  if (isLoading) {
    return <TableLoader />;
  }

  return (
    <form>
      <div className="row">
        {Object.keys(defaultState).map((key) => (
          <div key={key} className="form-group col-md-6">
            <label className="fw-bold">
              {t(cryptoPermissions[key].label)}
              {cryptoPermissions[key].required ? "*" : ""}
            </label>
            <div>
              <label style={{ paddingRight: "10px" }}>
                <input
                  type="radio"
                  name={key}
                  value="1"
                  checked={cryptoPermissions[key].value === "1"}
                  onChange={handleChange}
                />{" "}
                {t("Show")}
              </label>
              <label style={{ paddingRight: "10px" }}>
                <input
                  type="radio"
                  name={key}
                  value="0"
                  checked={cryptoPermissions[key].value === "0"}
                  onChange={handleChange}
                />{" "}
                {t("Hide")}
              </label>
            </div>
            <span style={{ color: "red" }}>
              {t(cryptoPermissionsError[key])}
            </span>
          </div>
        ))}
      </div>
      <Button
        className="btn btn1"
        variant={"primary"}
        isLoading={addingWidgetPermissions || updatingWidgetPermissions}
        onClick={handleSubmit}
      >
        {data && data.length > 0 ? t("Update") : t("Submit")}
      </Button>
    </form>
  );
};

export default Crypto;
