import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
const CopyToClipboard = ({ text }) => {
  const { t } = useTranslation();

  const textAreaRef = useRef(null);
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    if (textAreaRef.current) {
      textAreaRef.current.select();
      document.execCommand("copy");
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  };

  return (
    <div style={{ position: "relative", cursor: "pointer" }}>
      {copied && (
        <div
          style={{
            position: "absolute",
            top: "-25px",
            left: "50%",
            transform: "translateX(-50%)",
            background: "#000",
            color: "#fff",
            padding: "5px 10px",
            borderRadius: "5px",
            zIndex: "9999999",
          }}
        >
          {t('Copied!')}
        </div>
      )}
      <textarea
        style={{ position: "absolute", left: "-9999px" }}
        ref={textAreaRef}
        readOnly
        value={text}
      />
      {text && (
        <div onClick={copyToClipboard}>
          {copied ? (
            <i className="fa fa-check"></i>
          ) : (
            <i className="fa fa-copy"></i>
          )}
        </div>
      )}
    </div>
  );
};

export default CopyToClipboard;
