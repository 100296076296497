import { apiSlice } from "./api";
import {
  postSubIbApi,
  getSubIbsApi,
  updateSubIbApi,
  deleteSubIbApi,
} from "../components/constant/Api";

export const subIbApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addSubIb: builder.mutation({
      query: (post) => ({
        url: postSubIbApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["subib"],
    }),
    updateSubIb: builder.mutation({
      query: (post) => ({
        url: updateSubIbApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["subib"],
    }),
    deleteSubIb: builder.mutation({
      query: (post) => ({
        url: deleteSubIbApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["subib"],
    }),
    getSubIbs: builder.query({
      query: () => ({
        url: getSubIbsApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
  }),
});

export const {
  useAddSubIbMutation,
  useGetSubIbsQuery,
  useDeleteSubIbMutation,
  useUpdateSubIbMutation,
} = subIbApi;
