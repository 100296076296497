import { apiSlice } from "./api";
import {
  getUrlManagementApi,
  updateUrlManagementApi,
} from "../components/constant/Api";

export const UrlManagementApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUrlManagement: builder.query({
      query: () => ({
        url: getUrlManagementApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),

    updateUrlManagement: builder.mutation({
      query: (post) => ({
        url: updateUrlManagementApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["url-management"],
    }),
  }),
});

export const { useGetUrlManagementQuery, useUpdateUrlManagementMutation } =
  UrlManagementApi;
