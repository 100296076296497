import React, { useState, useEffect } from "react";
import { HeadWrapper } from "../Elements";
import { useParams } from "react-router-dom";
import { useGetKycQuery } from "../../../redux/kycApi";
import { KycTable } from "./kycTable";
import { useTranslation } from "react-i18next";

const Kyc = () => {
  const { t } = useTranslation();
  const { type } = useParams();

  const { data } = useGetKycQuery({ type });

  return (
    <HeadWrapper title={`${type} kyc`}>
      <div className="white-box">
        <KycTable data={data} />
      </div>
    </HeadWrapper>
  );
};

export default Kyc;
