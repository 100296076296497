import React from "react";
import Spinner from "react-bootstrap/Spinner";

const variants = {
  primary: "btn-primary",
  inverse: "btn-outline-primary",
  danger: "btn-danger",
};

const sizes = {
  sm: "btn-sm",
  md: "",
  lg: "btn-lg",
};

const Button = ({
  type = "button",
  className = "",
  variant = "primary",
  size = "md",
  isLoading = false,
  ...props
}) => {
  const combinedClassNames = [
    "btn",
    "flex justify-center items-center disabled:opacity-50 disabled:cursor-not-allowed shadow-sm focus:outline-none hover:opacity-80",
    variants[variant],
    sizes[size],
    className,
  ].join(" ");

  return (
    <button
      type={type}
      disabled={isLoading}
      className={combinedClassNames}
      {...props}
    >
      {isLoading && (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      )}
      {!isLoading}
      <span className="mx-2">{props.children}</span>
      {!isLoading}
    </button>
  );
};

Button.displayName = "Button";

export default Button;
