import { useMemo } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
// import { Box, Button, Tooltip, IconButton } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { mkConfig, generateCsv, download } from "export-to-csv"; //or use your library of choice here
import { formatDate } from "../../utils/format";
import Button from "../../widgets/Button";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});

const SupportTable = ({ data, onEdit, onDelete }) => {
  const { t } = useTranslation();
  const columns = useMemo(
    () => [
      {
        // accessorKey: "id",
        header: t("Serial No."),
        size: 20,
        Cell: ({ row }) => <span>{row.index + 1}</span>,
      },

      {
        accessorKey: "id",
        header: t("Ticket ID"),
        Cell: ({ row: { original } }) => <span>{original.id}</span>,
      },

      {
        accessorKey: "name",
        header: t("Name"),
      },

      {
        accessorKey: "email",
        header: t("Email")+'/'+t("Phone"),
        Cell: ({ row: { original } }) => (
          <span>
            {!original.mobile_number || original.mobile_number === ""
              ? original.email
              : original.mobile_number}
          </span>
        ),
      },
      {
        accessorKey: "issue",
        header: t("Subject"),
      },
      {
        header: t("Status"),
        accessorFn: (originalRow) =>
          Number(originalRow.status) ? "true" : "false",
        id: "isActive",
        filterVariant: "checkbox",
        Cell: ({ cell }) =>
          cell.getValue() === "true" ? "Resolved" : "Pending",
        size: 50,
      },

      {
        accessorKey: "createAt",
        header: t("Date"),
        enableEditing: false,
        Cell: ({ row }) => <span>{formatDate(row.original.createAt)}</span>,
      },
      {
        accessorKey: "id",
        header: t("Action"),
        enableEditing: false,
        Cell: ({ row }) => (
          <Link
            to={`/support/${row.id}`}
            className="btn btn1"
            variant="primary"
          >
            {row.original.status === "1" ? t("View") : t("Chat")}
          </Link>
        ),
      },
    ],
    [data]
  );

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data,
    // enableGrouping: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableRowSelection: false,
    columnFilterDisplayMode: "popover",
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    enableEditing: false,
    getRowId: (row) => row.id,
    // renderRowActions: ({ row, table }) => (
    //   <Box sx={{ display: "flex", gap: "1rem" }}>
    //     <Tooltip title="Edit">
    //       <IconButton onClick={() => onEdit(row.original)}>
    //         <EditIcon />
    //       </IconButton>
    //     </Tooltip>
    //     <Tooltip title="Delete">
    //       <IconButton color="error" onClick={() => onDelete(row.original)}>
    //         <DeleteIcon />
    //       </IconButton>
    //     </Tooltip>
    //   </Box>
    // ),
    // renderTopToolbarCustomActions: ({ table }) => (
    //   <Box
    //     sx={{
    //       display: "flex",
    //       gap: "16px",
    //       padding: "8px",
    //       flexWrap: "wrap",
    //     }}
    //   >
    //     <Button
    //       //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
    //       onClick={handleExportData}
    //       startIcon={<FileDownloadIcon />}
    //     >
    //       Export All
    //     </Button>

    //     {/* <Button
    //       disabled={table.getRowModel().rows.length === 0}
    //       //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
    //       onClick={() => handleExportRows(table.getRowModel().rows)}
    //       startIcon={<FileDownloadIcon />}
    //     >
    //       Export Page Rows
    //     </Button> */}
    //     <Button
    //       disabled={
    //         !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
    //       }
    //       //only export selected rows
    //       onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
    //       startIcon={<FileDownloadIcon />}
    //     >
    //       Export Selected
    //     </Button>
    //   </Box>
    // ),
  });

  return <MaterialReactTable table={table} />;
};

export default SupportTable;
