import React, { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import "./App.css";
import { Outlet } from "react-router-dom";
import Header from "./components/widgets/Header";
import Navbar from "./components/widgets/Navbar";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
function App() {
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state) => state.auth);
  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);

  return (
    <>
      {isAuthenticated && (
        <>
          <Header />
          <Navbar />
        </>
      )}
      <Outlet />
      <Toaster position="top-right" reverseOrder={true} />
    </>
  );
}

export default App;
