import {
  emailPattern,
  mobilePattern,
  passwordPattern,
  alphanumericPattern,
} from "../pattern/Pattern";

export function validateAlphanumeric(value) {
  if (!alphanumericPattern.test(value)) {
    return "Please enter only alphanumeric characters";
  }
}

export function validateEmail(email) {
  if (!emailPattern.test(email)) {
    return "Please enter a valid email";
  }

  return "";
}

export function validateRequired(value) {
  if (value.toString().trim() === "") {
    return "This field is required";
  }
  return "";
}

export function withFirstSpace(value) {
  if (value.toString().match(/^\s/)) {
    return `Please enter valid value without first space`;
  }
  return "";
}

export function validatePhoneNumber(phoneNumber) {
  if (phoneNumber.length < 4) {
    return "Please enter a valid phone number";
  }
  return "";
}

export function checkPassword(password) {
  if (!passwordPattern.test(password)) {
    return "Password must be 8 characters long, contain at least one upper case letter, one lower case letter, one number, and one special character";
  }
  return "";
}

export function checkMinLength(value, min) {
  if (value.length < min) {
    return `Length should be greater than or equal to ${min} characters`;
  }
}
