import React from "react";
import Button from "../../widgets/Button";
import CustomModal from "../../widgets/Modal";
import { handleToast } from "../../utils/handleToast";
import { useUserDeleteMutation } from "../../../redux/usersApi";
import { useTranslation } from "react-i18next";

export const UserDelete = ({ id, onClose, show, refetch, status }) => {
  const { t } = useTranslation();
  const [userDelete, { isLoading }] = useUserDeleteMutation();
  const handleDelete = async () => {
    const result = await userDelete({ id });

    handleToast(t,result);
    if (result?.data?.status) {
      refetch();
      onClose();
    }
  };
  return (
    <CustomModal
      isOpen={show}
      onClose={onClose}
      title={t("Delete User")}
      footer={
        <Button
          className="btn "
          variant={"danger"}
          isLoading={isLoading}
          onClick={handleDelete}
        >
          {t('Delete')}
        </Button>
      }
    >
      <div>{t('Are you sure you want to update Delete?')}</div>
    </CustomModal>
  );
};
