import React, { useState } from "react";
import TableLoader from "../../widgets/TableLoader";
import StocksTable from "./StocksTable";
import { ValidateInputFields } from "../../validations/ValidateInputFields";

import { handleToast } from "../../utils/handleToast";
import Button from "../../widgets/Button";
import CustomModal from "../../widgets/Modal";
import {
  useAddClearingHouseMutation,
  useGetClearingHouseQuery,
  useDeleteClearingHouseMutation,
  useDisableClearingHouseMutation,
  useUpdateClearingHouseMutation,
} from "../../../redux/clearingHouseApi";
import { useTranslation } from "react-i18next";
const Stocks = () => {
  const { t } = useTranslation();
  const { data: records, isLoading, refetch } = useGetClearingHouseQuery();
  const [addClearingHouse, { isLoading: addingClearingHouse }] =
    useAddClearingHouseMutation();
  const [deleteClearingHouse, { isLoading: deletingClearingHouse }] =
    useDeleteClearingHouseMutation();
  const [disableClearingHouse, { isLoading: disablingClearingHouse }] =
    useDisableClearingHouseMutation();

  const [updateClearingHouse, { isLoading: updatingClearingHouse }] =
    useUpdateClearingHouseMutation();

  const [editData, setEditData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [isDisable, setIsDisable] = useState(false);
  const [disableData, setDisableData] = useState(null);
  const [disabledStatus, setDisabledStatus] = useState(0);

  const [show, setShow] = useState(false);
  const [clearingHouse, setClearingHouse] = useState({
    name: { value: "", required: true },
    secret_key: { value: "", required: true },
    api_key: { value: "", required: true },
  });
  const [clearingHouseError, setClearingHouseError] = useState({
    name: "",
    secret_key: "",
    api_key: "",
  });

  const handleClose = () => {
    setClearingHouse({
      name: { value: "", required: true },
      secret_key: { value: "", required: true },
      api_key: { value: "", required: true },
    });
    setClearingHouseError(null);
    setEditData(null);
    setIsEdit(false);
    setShow(false);
    setIsDelete(false);
    setIsDisable(false);
  };

  const handleShow = () => setShow(true);

  const handleEdit = (data) => {
    setEditData(data);
    setIsEdit(true);
    setClearingHouse({
      name: { value: data.name, required: true },
      secret_key: { value: data.secret_key, required: true },
      api_key: { value: data.api_key, required: true },
    });
    handleShow();
  };

  const handleDelete = (data) => {
    setDeleteData(data);
    setIsDelete(true);
    handleShow();
  };

  const handleDisable = (data) => {
    setDisableData(data);
    setDisabledStatus(data.is_disabled);
    setIsDisable(true);
    handleShow();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setClearingHouse((prev) => {
      return { ...prev, [name]: { ...prev[name], value } };
    });

    const validationError = ValidateInputFields(
      name,
      value,
      clearingHouse[name].required
    );

    setClearingHouseError((prevError) => {
      return { ...prevError, [name]: validationError };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const handleDelete = async () => {
      const { id } = deleteData;
      const result = await deleteClearingHouse({ id });

      handleToast(t,result);
      if (result?.data?.status) {
        refetch();
        handleClose();
      }
    };

    const handleDisable = async () => {
      const { id, is_disabled } = disableData;
      const result = await disableClearingHouse({ id, is_disabled });

      handleToast(t,result);
      if (result?.data?.status) {
        refetch();
        handleClose();
      }
    };

    const handleUpdateOrAdd = async () => {
      const values = Object.fromEntries(
        Object.entries(clearingHouse).map(([key, { value }]) => [key, value])
      );

      for (let key in clearingHouse) {
        const validationError = ValidateInputFields(
          key,
          clearingHouse[key].value,
          clearingHouse[key].required
        );
        setClearingHouseError((prevError) => ({
          ...prevError,
          [key]: validationError,
        }));
        if (validationError) return;
      }

      const mutationResult = isEdit
        ? await updateClearingHouse({ id: editData.id, ...values })
        : await addClearingHouse(values);

      handleToast(t,mutationResult);
      if (mutationResult?.data?.status) {
        refetch();
        handleClose();
      }
    };

    isDelete
      ? handleDelete()
      : isDisable
      ? handleDisable()
      : handleUpdateOrAdd();
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="d-md-flex mb-3">
            <h3 className="page-title">{t("Stocks")}</h3>
            <div className=" ms-auto">
              <button
                disabled={addingClearingHouse}
                className="btn btn1"
                onClick={handleShow}
              >
                {t("Add Stocks")}
              </button>
            </div>
          </div>

          {isLoading ? (
            <TableLoader />
          ) : (
            <div className="white-box">
              <div className="table-responsive">
                <StocksTable
                  data={records}
                  onEdit={handleEdit}
                  onDelete={handleDelete}
                  onDisable={handleDisable}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <CustomModal
        isOpen={show}
        onClose={handleClose}
        title={
          isDelete
            ? t("Delete Clearing House")
            : isDisable && disabledStatus === "0"
            ? t("Enable Clearing House")
            : isDisable && disabledStatus === "1"
            ? t("Disable Clearing House")
            : isEdit
            ? t("Edit Clearing House")
            : t("Add Clearing House")
        }
        footer={
          <Button
            className="btn btn1"
            variant={isDelete ? "danger" : "primary"}
            isLoading={
              isDelete
                ? deletingClearingHouse
                : isDisable
                ? disablingClearingHouse
                : isEdit
                ? updatingClearingHouse
                : addingClearingHouse
            }
            onClick={handleSubmit}
          >
            {isDelete
              ? t("Delete")
              : isDisable && disabledStatus === "0"
              ? t("Enable")
              : isDisable && disabledStatus === "1"
              ? t("Disable")
              : isEdit
              ? t("Update")
              : t("Submit")}
          </Button>
        }
      >
        {isDelete ? (
          <div>{t("Are you sure you want to delete?")}</div>
        ) : isDisable ? (
          <div>
            {t(
              `Are you sure you want to ${
                disabledStatus === "0" ? "enable" : "disable"
              }?`
            )}
          </div>
        ) : (
          <form>
            <div className="form-group">
              <label className="fw-bold">{t("Name")}</label>
              <input
                type="text"
                className="form-control"
                placeholder={t("Enter Name")}
                name="name"
                onChange={handleChange}
                value={clearingHouse.name.value}
              />
              <span style={{ color: "red" }}>
                {t(clearingHouseError?.name)}
              </span>
            </div>
            <div className="form-group">
              <label className="fw-bold">{t("Secret Key")}</label>
              <input
                type="text"
                className="form-control"
                placeholder={t("Enter Secret Key")}
                name="secret_key"
                onChange={handleChange}
                value={clearingHouse.secret_key.value}
                disabled={isEdit}
              />
              <span style={{ color: "red" }}>
                {t(clearingHouseError?.secret_key)}
              </span>
            </div>

            <div className="form-group">
              <label className="fw-bold">{t("API Key")}</label>
              <input
                type="text"
                className="form-control"
                placeholder={t("Enter API Key")}
                name="api_key"
                onChange={handleChange}
                value={clearingHouse.api_key.value}
                disabled={isEdit}
              />
              <span style={{ color: "red" }}>
                {t(clearingHouseError?.api_key)}
              </span>
            </div>
          </form>
        )}
      </CustomModal>
    </div>
  );
};

export default Stocks;
