import { apiSlice } from "./api";
import { getKycApi } from "../components/constant/Api";

export const kycVendorApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // disableKycVendor: builder.mutation({
    //   query: (post) => ({
    //     url: disableKycVendorApi,
    //     method: "POST",
    //     body: post,
    //   }),

    //   invalidatesTags: (_) => ["kyc-vendor"],
    // }),

    getKyc: builder.query({
      query: ({ type }) => ({
        url: `${getKycApi}/${type}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
  }),
});

export const { useGetKycQuery } = kycVendorApi;
