import React, { useState, useEffect } from "react";
import { useGetBuyOrdersFxQuery } from "../../../redux/ReportsApi";
import TableLoader from "../../widgets/TableLoader";
import { BuyOrdersTableFx } from "./BuyOrderTableFx";
import { useTranslation } from "react-i18next";
const BuyOrdersFx = () => {
  const { t } = useTranslation();
  const [isActiveA, setIsActiveA] = useState(true);
  const [isActiveB, setIsActiveB] = useState(false);
  const [records, setRecords] = useState([]);
  const { data, isLoading } = useGetBuyOrdersFxQuery();

  console.log(data, "dataFXXXBYY");

  useEffect(() => {
    if (data && data.length > 0) {
      const filter = data.filter((order) => order.is_vendor === "1");
      setRecords(filter);
    }
  }, [data]);

  const toggleButton = (button) => {
    if (button === "1") {
      const filter = data.filter((order) => order.is_vendor === "1");
      setRecords(filter);
      setIsActiveA(true);
      setIsActiveB(false);
    } else if (button === "0") {
      const filter = data.filter((order) => order.is_vendor === "0");
      setRecords(filter);
      setIsActiveA(false);
      setIsActiveB(true);
    }
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="d-md-flex mb-3">
            <h3 className="page-title">{t("Buy Orders Fx")}</h3>
            <div className=" ms-auto"></div>
          </div>

          {isLoading ? (
            <TableLoader />
          ) : (
            <div className="white-box">
              <div className="row btn112">
                <div className="col-6">
                  <button
                    className={`btn  m-2 ${
                      isActiveA ? "btn-secondary2 " : "active"
                    }`}
                    onClick={() => toggleButton("1")}
                  >
                    {t("A Book")}
                  </button>
                  <button
                    className={`btn ${
                      isActiveB ? "btn-secondary2 " : "active"
                    }`}
                    onClick={() => toggleButton("0")}
                  >
                    {t("B Book")}
                  </button>
                </div>
              </div>
              <div className="table-responsive">
                <BuyOrdersTableFx data={records} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BuyOrdersFx;
