import React from "react";
import Button from "../../widgets/Button";
import CustomModal from "../../widgets/Modal";
import { handleToast } from "../../utils/handleToast";
import { useTwoFAMutation } from "../../../redux/usersApi";
import { useTranslation } from "react-i18next";

export const TwoFA = ({ id, onClose, show, refetch, status }) => {
  const { t } = useTranslation();
  const [userStatus, { isLoading }] = useTwoFAMutation();
  const handleStatus = async () => {
    const statusToUpdate = status === "Y" ? "N" : "Y";
    const result = await userStatus({ id, status: statusToUpdate });

    handleToast(t,result);
    if (result?.data?.status) {
      refetch();
      onClose();
    }
  };
  return (
    <CustomModal
      isOpen={show}
      onClose={onClose}
      title={t("Update Two FA Status")}
      footer={
        <Button
          className="btn btn1"
          variant={"danger"}
          isLoading={isLoading}
          onClick={handleStatus}
        >
          {t('Update Status')}
        </Button>
      }
    >
      <div>{t('Are you sure you want to update Status?')}</div>
    </CustomModal>
  );
};
