import { apiSlice } from "./api";
import {
  postKycVendorApi,
  getKycVendorApi,
  updateKycVendorApi,
  deleteKycVendorApi,
  disableKycVendorApi,
} from "../components/constant/Api";

export const kycVendorApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addKycVendor: builder.mutation({
      query: (post) => ({
        url: postKycVendorApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["kyc-vendor"],
    }),
    updateKycVendor: builder.mutation({
      query: (post) => ({
        url: updateKycVendorApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["kyc-vendor"],
    }),
    deleteKycVendor: builder.mutation({
      query: (post) => ({
        url: deleteKycVendorApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["kyc-vendor"],
    }),

    disableKycVendor: builder.mutation({
      query: (post) => ({
        url: disableKycVendorApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["kyc-vendor"],
    }),

    getKycVendor: builder.query({
      query: () => ({
        url: getKycVendorApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
  }),
});

export const {
  useAddKycVendorMutation,
  useGetKycVendorQuery,
  useDeleteKycVendorMutation,
  useDisableKycVendorMutation,
  useUpdateKycVendorMutation,
} = kycVendorApi;
