import { apiSlice } from "./api";
import {
  postClearingHouseApi,
  getClearingHouseApi,
  updateClearingHouseApi,
  disableClearingHouseApi,
  deleteClearingHouseApi,
} from "../components/constant/Api";

export const clearingHouseApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addClearingHouse: builder.mutation({
      query: (post) => ({
        url: postClearingHouseApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["clearing-house"],
    }),
    updateClearingHouse: builder.mutation({
      query: (post) => ({
        url: updateClearingHouseApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["clearing-house"],
    }),
    deleteClearingHouse: builder.mutation({
      query: (post) => ({
        url: deleteClearingHouseApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["clearing-house"],
    }),
    disableClearingHouse: builder.mutation({
      query: (post) => ({
        url: disableClearingHouseApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["data-provider"],
    }),

    getClearingHouse: builder.query({
      query: () => ({
        url: getClearingHouseApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
  }),
});

export const {
  useAddClearingHouseMutation,
  useGetClearingHouseQuery,
  useDeleteClearingHouseMutation,
  useDisableClearingHouseMutation,
  useUpdateClearingHouseMutation,
} = clearingHouseApi;
