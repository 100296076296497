import { useTranslation } from "react-i18next";

export const HeadWrapper = ({ children, title, button }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="d-md-flex mb-3">
            <h3 className="page-title text-capitalize">{t(title)}</h3>
            <div className=" ms-auto">{button}</div>
          </div>

          {children}
        </div>
      </div>
    </div>
  );
};
