import React, { useState } from "react";
import TableLoader from "../../widgets/TableLoader";
import CurrencyTable from "./CurrencyTable";
import {
  useGetFxPairsQuery,
  useUpdateFxCoinMutation,
} from "../../../redux/fxApi";
import CustomModal from "../../widgets/Modal";
import Button from "../../widgets/Button";
import { ImageValid } from "../../validations/ImageValid";
import { createFormDataFromObject } from "../../utils/formData";
import { ValidateInputFields } from "../../validations/ValidateInputFields";
import { handleToast } from "../../utils/handleToast";
import { baseUrl } from "../../constant/BaseUrl";
import { useTranslation } from "react-i18next";
const url = `${baseUrl.replace("/api-admin/v1", "")}`;

const defaultCryptoState = {
  symbol: { value: "", required: true },
  currencyImage: { value: "", required: true },
  companyName: { value: "", required: true },
  is_vendor: { value: "1", required: true },
};
const defaultCryptoError = {
  currencyImage: "",
  companyName: "",
  is_vendor: "",
};

const Currency = () => {
  const { t } = useTranslation();
  const [updateFxCoin, { isLoading: updatingCrypto }] =
    useUpdateFxCoinMutation();
  const { data, isLoading, refetch } = useGetFxPairsQuery();
  const [isEdit, setIsEdit] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [show, setShow] = useState(false);
  const [symbolFx, setSymbolFx] = useState(defaultCryptoState);
  const [symbolFxError, setSymbolFxError] = useState(defaultCryptoError);

  const [editData, setEditData] = useState(null);

  const handleClose = () => {
    setShow(false);
    setImagePreview(null);
    setEditData(null);
    setSymbolFx(defaultCryptoState);
    setSymbolFxError(defaultCryptoError);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const error = ImageValid(file);

    if (error) {
      setSymbolFxError((prevError) => ({
        ...prevError,
        currencyImage: error,
      }));
      return;
    }

    setSymbolFx((prevCrypto) => ({
      ...prevCrypto,
      currencyImage: { value: file, required: true },
    }));

    const reader = new FileReader();

    reader.onloadend = () => {
      setImagePreview(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
      setSymbolFxError((prevError) => ({
        ...prevError,
        currencyImage: "",
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "companyName") {
      setSymbolFx((prevCrypto) => ({
        ...prevCrypto,
        companyName: { value: value, required: true },
      }));

      if (!value) {
        setSymbolFxError((prevError) => ({
          ...prevError,
          companyName: "This field is required",
        }));
        return;
      } else {
        setSymbolFxError((prevError) => ({
          ...prevError,
          companyName: "",
        }));
      }
    }

    if (name === "is_vendor") {
      const checked = e.target.checked;
      setSymbolFx((prev) => {
        return {
          ...prev,
          [name]: { ...prev[name], value: checked ? "1" : "0" },
        };
      });
    }
  };

  const handleEdit = (data) => {
    setEditData(data);
    setIsEdit(true);
    if (data.icon) {
      setImagePreview(
        data.icon ? `${url + "/static/currencyImage/" + data.icon}` : ""
      );
    }
    setSymbolFx({
      symbol: { value: data.symbol, required: true },
      currencyImage: { value: `${data.icon}`, required: true },
      companyName: { value: `${data.company_name}`, required: true },
    });
    setShow(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const handleUpdateOrAdd = async () => {
      const values = Object.fromEntries(
        Object.entries(symbolFx).map(([key, { value }]) => [key, value])
      );

      for (let key in symbolFx) {
        const validationError = ValidateInputFields(
          key,
          symbolFx[key].value,
          symbolFx[key].required
        );
        setSymbolFxError((prevError) => ({
          ...prevError,
          [key]: validationError,
        }));
        if (validationError) return;
      }

      const formData =
        isEdit && createFormDataFromObject({ id: editData.id, ...values });

      const mutationResult = isEdit && (await updateFxCoin(formData));

      handleToast(t, mutationResult);
      if (mutationResult?.data?.status) {
        handleClose();
        refetch();
      }
    };

    handleUpdateOrAdd();
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="d-md-flex mb-3">
            <h3 className="page-title">{t("Fx Currency Pairs")}</h3>
            <div className=" ms-auto"></div>
          </div>

          {isLoading ? (
            <TableLoader />
          ) : (
            <div className="white-box">
              <div className="table-responsive">
                <CurrencyTable data={data} onEdit={handleEdit} />
              </div>
            </div>
          )}
        </div>
      </div>
      <CustomModal
        isOpen={show}
        onClose={handleClose}
        title={t("Edit Currency")}
        footer={
          <Button
            className="btn btn1"
            variant={"primary"}
            isLoading={updatingCrypto}
            onClick={handleSubmit}
          >
            {t("Update")}
          </Button>
        }
      >
        <form>
          <div className="row">
            <div className="form-group col-md-6">
              <label className="fw-bold">{t("Symbol")}*</label>
              <input
                type="text"
                className="form-control"
                placeholder={t("Enter Symbol")}
                name="symbol"
                value={symbolFx.symbol.value}
                disabled={isEdit}
              />
              <span style={{ color: "red" }}>{t(symbolFxError?.symbol)}</span>
            </div>
            <div className="form-group col-md-6">
              <label className="fw-bold">{t("Currency Image")}*</label>
              <input
                type="file"
                className="form-control"
                accept="image/*"
                name="currencyImage"
                // value={symbolFx.currencyImage?.value}
                onChange={handleImageChange}
              />
              {imagePreview && (
                <img
                  src={imagePreview}
                  alt="Currency"
                  className="input_upload_img"
                />
              )}
              <span style={{ color: "red" }}>
                {t(symbolFxError?.currencyImage)}
              </span>
            </div>
            <div className="form-group col-md-6">
              <label className="fw-bold">{t("Company Name")}*</label>
              <input
                type="text"
                className="form-control"
                placeholder={t("Enter Company Name")}
                name="company"
                value={symbolFx.companyName.value}
                onChange={handleChange}
              />
              <span style={{ color: "red" }}>
                {t(symbolFxError?.companyName)}
              </span>
            </div>

            <div className="form-group">
              <div className="form-check form-check-inline">
                <input
                  type="checkbox"
                  id={`is_vendor`}
                  name={`is_vendor`}
                  value={symbolFx.is_vendor?.value}
                  checked={symbolFx.is_vendor?.value === "1"}
                  onChange={handleChange}
                  className="form-check-input"
                />
                <label htmlFor={`is_vendor`} className="form-check-label">
                  {t("A Book")}
                </label>
              </div>
            </div>
          </div>
        </form>
      </CustomModal>
    </div>
  );
};

export default Currency;
