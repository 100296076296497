import React from "react";
import TableLoader from "../../widgets/TableLoader";
import { useGetNotificationsQuery } from "../../../redux/notificationsApi";
import Alert from "react-bootstrap/Alert";
import { formatDate } from "../../utils/format";
import { useTranslation } from "react-i18next";

const Notifications = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetNotificationsQuery();

  const notificationsItems = () => {
    if (!data || !data.notifications) {
      return null;
    }

    return (
      <>
        {data.notifications.map((item, index) => (
          <Alert key={index} variant="secondary">
            <div style={{ display: "flex" }}>
              <span className="dot"></span>
              <div style={{ paddingLeft: "10px" }}>
                <strong>
                  {item.message} {item.name}
                </strong>
                <br />
                <span>{formatDate(item.created_at)}</span>
              </div>
            </div>
          </Alert>
        ))}
      </>
    );
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="d-md-flex mb-3">
            <h3 className="page-title">{t("Notifications")}</h3>
            <div className=" ms-auto"></div>
          </div>

          {isLoading ? (
            <TableLoader />
          ) : (
            <div className="white-box">
              <div className="table-responsive">{notificationsItems()}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Notifications;
