import React from "react";
import "./pageLoader.css";

const PageLoader = () => {
  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid d-flex justify-content-center align-content-center flex-wrap">
          <div className="spinner"></div>
        </div>
      </div>
    </div>
  );
};

export default PageLoader;
