import { apiSlice } from "./api";
import {
  postIbApi,
  getIbsApi,
  updateIbApi,
  deleteIbApi,
} from "../components/constant/Api";

export const ibApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addIb: builder.mutation({
      query: (post) => ({
        url: postIbApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["ib"],
    }),
    updateIb: builder.mutation({
      query: (post) => ({
        url: updateIbApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["ib"],
    }),
    deleteIb: builder.mutation({
      query: (post) => ({
        url: deleteIbApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["ib"],
    }),
    getIbs: builder.query({
      query: () => ({
        url: getIbsApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
  }),
});

export const {
  useAddIbMutation,
  useGetIbsQuery,
  useDeleteIbMutation,
  useUpdateIbMutation,
} = ibApi;
