import moment from "moment";

export const formatDate = (date) => {
  return moment(date).format("MMM D, YYYY h:mm:ss A");
};

export const formatePermissions = (permissionsArray) => {
  const permissions = [{}];

  permissionsArray.forEach((permissionGroup) => {
    const modulePermissions = {};
    const modulePermissionDetails = {};

    let hasPermission = false; // Flag to track if any permission is allowed

    permissionGroup.permissions.forEach((permission) => {
      const read = permission.read === "1";
      const write = permission.write === "1";
      const del = permission.delete === "1";

      const permissionObj = {
        read,
        write,
        delete: del,
      };

      modulePermissionDetails[permission.name] = permissionObj;

      // Check if any permission is granted
      if (read || write || del) {
        hasPermission = true;
      }
    });

    // Set status based on whether any permission is allowed
    modulePermissions.status = hasPermission;

    modulePermissions.permissions = modulePermissionDetails;

    permissions[0][permissionGroup.moduleName] = modulePermissions;
  });

  return permissions[0];
};
