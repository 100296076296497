import { apiSlice } from "./api";
import {
  getFxInterest,
  getFxPairs,
  updateFxCoin,
  updateFxInterest,
} from "../components/constant/Api";

export const commissionApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFxPairs: builder.query({
      query: () => ({
        url: getFxPairs,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
    updateFxCoin: builder.mutation({
      query: (post) => ({
        url: updateFxCoin,
        method: "POST",
        body: post,
      }),
    }),
    getFxInterest: builder.query({
      query: () => ({
        url: getFxInterest,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
    updateFxInterest: builder.mutation({
      query: (post) => ({
        url: updateFxInterest,
        method: "POST",
        body: post,
      }),
    }),
  }),
});

export const {
  useGetFxPairsQuery,
  useUpdateFxCoinMutation,
  useGetFxInterestQuery,
  useUpdateFxInterestMutation,
} = commissionApi;
