import React, { useEffect } from "react";

const CustomModal = ({
  isOpen,
  onClose,
  title,
  children,
  footer,
  maxWidth = "600px",
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  const overlayStyle = {
    display: isOpen ? "flex" : "none",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.5)",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
    overflowY: "auto",
  };

  const modalStyle = {
    background: "#fff",
    borderRadius: "5px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
    maxWidth: maxWidth,
    width: "100%",
    textAlign: "left",
    position: "relative",
    maxHeight: "90vh",
    overflow: "inherit",
    scrollbarWidth: "none",
  };

  const headerStyle = {
    background: "#FFFFFF",
    color: "#08223e",
    padding: "10px",
    borderBottom: "1px solid rgba(120, 130, 140, 0.13)",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    fontSize: "18px",
  };

  const closeBtnStyle = {
    cursor: "pointer",
    position: "absolute",
    top: "0px",
    right: "10px",
    fontSize: "30px",
    color: "rgba(120, 130, 140, 3)",
  };
  const footerStyle = {
    borderTop: "1px solid #ddd",
    padding: "10px",
    textAlign: "right",
  };

  return (
    <div style={overlayStyle} onClick={onClose} className="main-model">
      <div style={modalStyle} onClick={(e) => e.stopPropagation()}>
        <div style={headerStyle} className="main-model-header">
          <b>{title}</b>
          <span style={closeBtnStyle} onClick={onClose}>
            &times;
          </span>
        </div>
        <div style={{ padding: "20px" }} className="main-model-body">{children}</div>
        <div style={footerStyle} className="main-model-footer">{footer}</div>
      </div>
    </div>
  );
};

export default CustomModal;
