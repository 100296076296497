import React from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";

function Navbar() {
  return (
    <div>
      <div></div>
      <Sidebar />
    </div>
  );
}
export default Navbar;
