import { useMemo } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button, Tooltip, IconButton } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { mkConfig, generateCsv, download } from "export-to-csv"; //or use your library of choice here
import { formatDate } from "../../utils/format";
import { useTranslation } from "react-i18next";

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});

const AccountBrokerTable = ({ data, onEdit, onDelete, handleUserBalances }) => {
  const { t } = useTranslation();
  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "#",
        size: 80,
        Cell: ({ row }) => <span>{row.index + 1}</span>,
      },
      {
        accessorKey: "api_key",
        header: t('Api key'),
        Cell: ({ row: { original } }) => (
          <span>{original.api_key.slice(0, 20)}XXXX</span>
        ),
      },
      {
        accessorKey: "secret_key",
        header: t('Secret Key'),
        Cell: ({ row: { original } }) => (
          <span>{original.secret_key.slice(0, 20)}XXXX</span>
        ),
      },
      {
        accessorKey:"status",
        header: t('Status'),
        Cell: ({ row: { original } }) => (
          <span>{Number(original.status) ? "Active" : "Deactivate"}</span>
        ),
      },
      {
        accessorKey: "createdAt",
        header: t("Date"),
        enableEditing: false,
        Cell: ({ row }) => <span>{formatDate(row.original.createdAt)}</span>,
      },
      {
        accessorKey: "actions",
        header: t("Permissions"),
        Cell: ({ row }) => (
          <Tooltip title="Add Permission">
            <button
              className="btn btn1"
              color="error"
              onClick={() => handleUserBalances(row.original)}
            >
              <span>Add Permissions</span>
            </button>
          </Tooltip>
        ),
      },
    ],
    [data]
  );

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data,
    // enableGrouping: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableRowSelection: false,
    columnFilterDisplayMode: "popover",
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    enableEditing: false,
    getRowId: (row) => row.id,
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip title="Edit">
          <IconButton onClick={() => onEdit(row.original)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => onDelete(row.original)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          {t('Export All')}
        </Button>

        {/* <Button
          disabled={table.getRowModel().rows.length === 0}
          //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
          onClick={() => handleExportRows(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Page Rows
        </Button> */}
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          //only export selected rows
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          {t('Export Selected')}
        </Button>
      </Box>
    ),
  });

  return <MaterialReactTable table={table} />;
};

export default AccountBrokerTable;
