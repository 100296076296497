import { apiSlice } from "./api";
import {
  getWidgetPermissionsApi,
  postWidgetPermissionsApi,
  updateWidgetPermissionsApi,
} from "../components/constant/Api";

export const widgetPermissionsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getWidgetPermissions: builder.query({
      query: ({ userId, type }) => ({
        url: `${getWidgetPermissionsApi}/${userId}/${type}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
      invalidatesTags: (_) => ["widgetPermissions"],
    }),
    addWidgetPermissions: builder.mutation({
      query: (post) => ({
        url: postWidgetPermissionsApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["widgetPermissions"],
    }),
    updateWidgetPermissions: builder.mutation({
      query: (post) => ({
        url: updateWidgetPermissionsApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["widgetPermissions"],
    }),
  }),
});

export const {
  useAddWidgetPermissionsMutation,
  useGetWidgetPermissionsQuery,
  useUpdateWidgetPermissionsMutation,
} = widgetPermissionsApi;
