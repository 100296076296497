import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  useCryptoPositionsQuery,
  useEquityPositionsQuery,
  useFxPositionsQuery,
  useUserHistoryByTypeQuery,
} from "../../../redux/ReportsApi";
import {
  UserWalletTransTable,
  BuyOrdersTable,
  SellOrdersTable,
  BalancesTable,
  CryptoPositions,
  FXPositions,
  EquityPositions,
} from "../Elements/";
import TableLoader from "../../widgets/TableLoader";
import { baseUrl } from "../../constant/BaseUrl";
import { useTranslation } from "react-i18next";

const url = `${baseUrl.replace("/api-admin/v1", "")}`;

const tabObj = {
  0: "balance",
  // 1: "buy",
  // 2: "sell",
  1: "Deposit",
  2: "Withdrawal",
};

export const UserInfoTabs = ({ userId }) => {
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);
  const [type, setType] = useState("balance");
  const [balanceData, setBalanceData] = useState([]);
  const [cryptoData, setCryptoData] = useState([]);
  const [fxData, setFxData] = useState([]);
  const [equityData, setEquityData] = useState([]);

  const { data, isLoading, isFetching, refetch } = useUserHistoryByTypeQuery({
    userId,
    type,
  });

  const {
    data: Cdata,
    isLoading: cryptoLoading,
    refetch: cryptoRefetch,
  } = useCryptoPositionsQuery({
    userId,
  });

  const {
    data: Fdata,
    isLoading: fxLoading,
    refetch: fxRefetch,
  } = useFxPositionsQuery({
    userId,
  });

  const {
    data: Edata,
    isLoading: equityLoading,
    refetch: equityRefetch,
  } = useEquityPositionsQuery({
    userId,
  });

  useEffect(() => {
    refetch();
    cryptoRefetch();
    fxRefetch();
    equityRefetch();
  }, [type]);

  const handleTabChange = (index) => {
    setTabIndex(index);
    setType(tabObj[index]);
    const typeApi = tabObj[index];
    console.log(index, "innddex", typeApi);
  };

  useEffect(() => {
    if (type === "balance" && data) {
      const balances = data.filter((value) => {
        const hasWalletAmount = value.getValue.main[0].coin_amount;
        const hasSpotAmount = value.getValue.spot[0].coin_amount;

        return hasWalletAmount || hasSpotAmount || 0;
      });
      setBalanceData(balances);
    }

    if (Cdata) {
      setCryptoData(Cdata);
    }

    if (Fdata) {
      setFxData(Fdata);
    }

    if (Edata) {
      setEquityData(Edata);
    }
  }, [type, data, Cdata, Fdata, Edata]);

  console.log("cryptoData", cryptoData);
  return (
    <div className="parent-tabs">
      <h3>{t("Portfolio")} (USDT): 0.000</h3>
      <Tabs
        className="level-1-panels row"
        selectedIndex={tabIndex}
        onSelect={handleTabChange}
      >
        <TabList className="text-uppercase">
          <Tab>{t("Balance")}</Tab>
          {/* <Tab>Buy</Tab>
          <Tab>Sell</Tab> */}
          <Tab>{t("Deposit")}</Tab>
          <Tab>{t("Withdraw")}</Tab>
          <Tab>{t("Crypto Positions")}</Tab>
          <Tab>{t("FX Positions")}</Tab>
          <Tab>{t("Equity Positions")}</Tab>
        </TabList>

        {isLoading || isFetching ? (
          <TableLoader />
        ) : (
          <>
            <TabPanel>
              <BalancesTable data={balanceData} />
            </TabPanel>
            {/* <TabPanel>
              <BuyOrdersTable data={data} />
            </TabPanel>
            <TabPanel>
              <SellOrdersTable data={data} />
            </TabPanel> */}
            <TabPanel>
              <UserWalletTransTable data={data} />
            </TabPanel>
            <TabPanel>
              <UserWalletTransTable data={data} />
            </TabPanel>
            <TabPanel>
              <CryptoPositions data={cryptoData} />
            </TabPanel>
            <TabPanel>
              <FXPositions data={fxData} />
            </TabPanel>
            <TabPanel>
              <EquityPositions data={equityData} />
            </TabPanel>
          </>
        )}
      </Tabs>
    </div>
  );
};
