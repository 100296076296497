import { createSlice } from "@reduxjs/toolkit";
import { formatePermissions } from "../components/utils/format";
import { decryptData } from "../components/utils/decryptData";

const user = localStorage.getItem("userInfo")
  ? decryptData(localStorage.getItem("userInfo")).userInfo
  : null;

const permissions = localStorage.getItem("userInfo")
  ? decryptData(localStorage.getItem("userInfo")).userPermissions
  : null;

const initialState = {
  userInfo: localStorage.getItem("userInfo")
    ? localStorage.getItem("userInfo")
    : null,
  user,
  permissions,
  isAuthenticated: localStorage.getItem("jwtToken") ? true : false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      localStorage.setItem("userInfo", action.payload);
      const permission = decryptData(action.payload);
      state.permissions = formatePermissions(permission.userPermissions);
      state.user = permission.userInfo;
      state.userInfo = action.payload;
      state.isAuthenticated = true;
    },

    logout: (state, action) => {
      state.isAuthenticated = false;
      state.userInfo = null;
      state.user = null;
      state.permissions = null;
      localStorage.removeItem("jwtToken");
      localStorage.removeItem("userInfo");
    },
  },
});

export const { setCredentials, logout } = authSlice.actions;

export default authSlice.reducer;
