import { apiSlice } from "./api";
import {
  postBrokerApi,
  getBrokersApi,
  updateBrokerApi,
  deleteBrokerApi,
  getIBPermissionsApi,
  assignCoinsIBApi,
  postBrokerCredentialsApi,
  getBrokerCredentialsApi,
} from "../components/constant/Api";

export const brokerApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addBroker: builder.mutation({
      query: (post) => ({
        url: postBrokerApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["broker"],
    }),
    updateBroker: builder.mutation({
      query: (post) => ({
        url: updateBrokerApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["broker"],
    }),
    deleteBroker: builder.mutation({
      query: (post) => ({
        url: deleteBrokerApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["broker"],
    }),
    assignIBCoins: builder.mutation({
      query: (post) => ({
        url: assignCoinsIBApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["broker"],
    }),
    getIbPermissions: builder.query({
      query: ({ ibId }) => ({
        url: `${getIBPermissionsApi}/${ibId}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
    getBrokers: builder.query({
      query: () => ({
        url: getBrokersApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
    postBrokerCredentials: builder.mutation({
      query: (post) => ({
        url: postBrokerCredentialsApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["broker"],
    }),
    getBrokerCredentials: builder.query({
      query: () => ({
        url: getBrokerCredentialsApi,
        method: "GET",
      }),
      invalidatesTags: (_) => ["account"],
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
  }),
});

export const {
  useAddBrokerMutation,
  useGetBrokersQuery,
  useDeleteBrokerMutation,
  useUpdateBrokerMutation,
  useGetIbPermissionsQuery,
  useAssignIBCoinsMutation,
  useGetBrokerCredentialsQuery,
  usePostBrokerCredentialsMutation,
} = brokerApi;
