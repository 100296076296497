import React, { useState } from "react";
import TableLoader from "../../widgets/TableLoader";
import FxInterestRateTable from "./FxInterestRateTable";
import {
  useGetFxInterestQuery,
  useUpdateFxInterestMutation,
} from "../../../redux/fxApi";
import CustomModal from "../../widgets/Modal";
import Button from "../../widgets/Button";
import { ImageValid } from "../../validations/ImageValid";
import { createFormDataFromObject } from "../../utils/formData";
import { ValidateInputFields } from "../../validations/ValidateInputFields";
import { handleToast } from "../../utils/handleToast";
import { baseUrl } from "../../constant/BaseUrl";
import { useTranslation } from "react-i18next";
const url = `${baseUrl.replace("/api-admin/v1", "")}`;
const defaultCryptoState = {
  rate: { value: "", required: true },
  symbol: { value: "", required: true },
};
const defaultCryptoError = {
  rate: "",
  symbol: "",
};

const FxInterestRate = () => {
  const { t } = useTranslation();
  const [updateFxCoin, { isLoading: updatingCrypto }] =
    useUpdateFxInterestMutation();
  const { data, isLoading, refetch } = useGetFxInterestQuery();
  const [isEdit, setIsEdit] = useState(false);
  const [show, setShow] = useState(false);
  const [symbolFx, setSymbolFx] = useState(defaultCryptoState);
  const [symbolFxError, setSymbolFxError] = useState(defaultCryptoError);

  const [editData, setEditData] = useState(null);

  const handleClose = () => {
    setShow(false);
    setEditData(null);
    setSymbolFx(defaultCryptoState);
    setSymbolFxError(defaultCryptoError);
  };

  const handleChangeCompany = (e) => {
    const value = e.target.value;

    setSymbolFx((prevCrypto) => ({
      ...prevCrypto,
      rate: { value: value, required: true },
    }));

    if (!value) {
      setSymbolFxError((prevError) => ({
        ...prevError,
        rate: "This field is required",
      }));
      return;
    }

    if (value >= 100) {
      setSymbolFxError((prevError) => ({
        ...prevError,
        rate: "Rate cannot be greater than 100",
      }));
      return;
    }

    setSymbolFxError((prevError) => ({
      ...prevError,
      rate: "",
    }));
  };

  const handleEdit = (data) => {
    setEditData(data);
    setIsEdit(true);

    setSymbolFx({
      symbol: { value: data.currency_code, required: true },
      rate: { value: `${data.interest_rate}`, required: true },
    });
    setShow(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const handleUpdateOrAdd = async () => {
      const values = Object.fromEntries(
        Object.entries(symbolFx).map(([key, { value }]) => [key, value])
      );

      for (let key in symbolFx) {
        const validationError = ValidateInputFields(
          key,
          symbolFx[key].value,
          symbolFx[key].required
        );
        setSymbolFxError((prevError) => ({
          ...prevError,
          [key]: validationError,
        }));
        if (validationError) return;
      }

      const data = {
        ...values,
      };

      const mutationResult = isEdit && (await updateFxCoin(data));

      handleToast(t,mutationResult);
      if (mutationResult?.data?.status) {
        handleClose();
        refetch();
      }
    };

    handleUpdateOrAdd();
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="d-md-flex mb-3">
            <h3 className="page-title">{t("Manage Interest Rate")}</h3>
            <div className=" ms-auto"></div>
          </div>

          {isLoading ? (
            <TableLoader />
          ) : (
            <div className="white-box">
              <div className="table-responsive">
                <FxInterestRateTable data={data} onEdit={handleEdit} />
              </div>
            </div>
          )}
        </div>
      </div>
      <CustomModal
        isOpen={show}
        onClose={handleClose}
        title={t("Edit Interest Rate")}
        footer={
          <Button
            className="btn btn1"
            variant={"primary"}
            isLoading={updatingCrypto}
            onClick={handleSubmit}
          >
            {t("Update")}
          </Button>
        }
      >
        <form>
          <div className="row">
            <div className="form-group col-md-6">
              <label className="fw-bold">{t("Symbol")}*</label>
              <input
                type="text"
                className="form-control"
                placeholder={t("Enter Symbol")}
                name="symbol"
                value={symbolFx.symbol.value}
                disabled={isEdit}
              />
              <span style={{ color: "red" }}>{t(symbolFxError?.symbol)}</span>
            </div>
            <div className="form-group col-md-6">
              <label className="fw-bold">{t("Interest Rate")}*</label>
              <input
                type="text"
                className="form-control"
                placeholder={t("Enter Interest Rate")}
                name="rate"
                value={symbolFx.rate.value}
                onChange={handleChangeCompany}
              />
              <span style={{ color: "red" }}>{t(symbolFxError?.rate)}</span>
            </div>
          </div>
        </form>
      </CustomModal>
    </div>
  );
};

export default FxInterestRate;
