import React from "react";
import CollectionsTable from "./CollectionTable";
import TableLoader from "../../../widgets/TableLoader";
import { useGetCommissionReportsQuery } from "../../../../redux/commissionApi";
import { useTranslation } from "react-i18next";

const SellCollectionsFx = () => {
  const { t } = useTranslation();

  const { data, isLoading } = useGetCommissionReportsQuery({
    type: "sell",
    segment: "forex",
  });
  console.log(data, "sell");
  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="d-md-flex mb-3">
            <h3 className="page-title">{t("Sell Commission Forex")} </h3>
            <div className=" ms-auto"></div>
          </div>

          {isLoading ? (
            <TableLoader />
          ) : (
            <div className="white-box">
              <div className="table-responsive">
                <CollectionsTable data={data} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SellCollectionsFx;
