import React, { useState, useMemo, useEffect } from "react";
import { useAddIbMutation, useUpdateIbMutation } from "../../../redux/ibApi";
import { useGetBrokersQuery } from "../../../redux/brokerApi";
import { ValidateInputFields } from "../../validations/ValidateInputFields";
import Button from "../../widgets/Button";
import { handleToast } from "../../utils/handleToast";
import PhoneInput from "react-phone-input-2";
import { useGetAdminsQuery } from "../../../redux/adminApi";
import { useSelector } from "react-redux";
import {
  findBrokerById,
  findAdminById,
  filterBrokersByAdminId,
} from "../Elements/userUtils";
import { useTranslation } from "react-i18next";

const Profile = ({ isEdit, data, isNew, refetch, onClose, getNewUser }) => {
  const { t } = useTranslation();
  const {
    user: { role: userType },
  } = useSelector((state) => state.auth);
  const [addIb, { isLoading: addingIb }] = useAddIbMutation();
  const [updateIb, { isLoading: updatingIb }] = useUpdateIbMutation();
  const { data: brokers, isSuccess: gotBrokers } = useGetBrokersQuery(
    {},
    { skip: userType === "Broker" }
  );
  const { data: admins, isSuccess: gotAdmins } = useGetAdminsQuery(
    {},
    { skip: userType === "Admin" || userType === "Broker" }
  );
  const [currantBrokers, setCurrantBrokers] = useState([]);
  const [ib, setIb] = useState({
    name: { value: "", required: true },
    email: { value: "", required: true },
    mobile_number: { value: "", required: true },
    adminId: {
      value: "",
      required: userType === "Broker" || userType === "Admin" ? false : true,
    },
    brokerId: {
      value: "",
      required: userType === "Broker" ? false : true,
    },
    password: { value: "", required: true },
  });
  const [ibError, setIbError] = useState({
    name: "",
    email: "",
    mobile_number: "",
    adminId: "",
    brokerId: "",
    password: "",
  });

  const [selectedSegments, setSelectedSegments] = useState({
    crypto: false,
    forex: false,
    equity: false,
  });
  const [checkboxError, setCheckboxError] = useState("");

  useEffect(() => {
    if (
      !selectedSegments.crypto &&
      !selectedSegments.forex &&
      !selectedSegments.equity
    ) {
      setCheckboxError("At least one segment must be selected");
    } else {
      setCheckboxError("");
    }
  }, [selectedSegments]);

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setSelectedSegments((prev) => ({ ...prev, [name]: checked }));
  };

  useEffect(() => {
    console.log(userType, "userType", gotBrokers);
    if (data && userType === "Admin" && gotBrokers) {
      handleEdit(data);
      return;
    }
    if (data && userType === "Broker") {
      handleEdit(data);
      return;
    }
    if (data && gotBrokers && gotAdmins) {
      handleEdit(data);
    }
  }, [data, gotBrokers, gotAdmins]);

  useEffect(() => {
    if (userType === "Admin") {
      setCurrantBrokers(brokers);
    }
  }, [brokers, userType, isEdit, ib]);

  console.log(data, "data444");

  const handleEdit = (data) => {
    console.log(data, "data");
    if (userType !== "Broker") {
      const brokerId = findBrokerById(brokers, data?.refred_by_id);
      const adminId = findAdminById(admins, brokerId?.refred_by_id);
      const adminBrokers = filterBrokersByAdminId(brokers, adminId?.id);

      setCurrantBrokers(adminBrokers);

      // return;
      setIb({
        name: { value: data.name, required: true },
        email: { value: data.email, required: true },
        mobile_number: { value: `${data.mobile_number}`, required: true },
        adminId: {
          value: adminId?.id || "",
          required:
            userType === "Broker" || userType === "Admin" ? false : true,
        },
        brokerId: {
          value: data.refred_by_id,
          required:
            userType === "Broker" || userType === "Admin" ? false : true,
        },
        password: { value: "", required: false },
      });
    } else {
      // return;
      setIb({
        name: { value: data.name, required: true },
        email: { value: data.email, required: true },
        mobile_number: { value: `${data.mobile_number}`, required: true },
        adminId: {
          value: "",
          required:
            userType === "Broker" || userType === "Admin" ? false : true,
        },
        brokerId: {
          value: data.refred_by_id,
          required:
            userType === "Broker" || userType === "Admin" ? false : true,
        },
        password: { value: "", required: false },
      });
    }

    setSelectedSegments({
      crypto: data.is_crypto_enabled === "yes" ? true : false,
      forex: data.is_fx_enabled === "yes" ? true : false,
      equity: data.is_equity_enabled === "yes" ? true : false,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setIb((prev) => {
      return { ...prev, [name]: { ...prev[name], value } };
    });

    const validationError = ValidateInputFields(name, value, ib[name].required);

    if (name === "adminId") {
      const adminBrokers = filterBrokersByAdminId(brokers, value);
      setCurrantBrokers(adminBrokers);
    }

    setIbError((prevError) => {
      return { ...prevError, [name]: validationError };
    });
  };

  const selectorBody = useMemo(() => {
    return admins?.map((admin) => (
      <option value={admin.id} key={admin.id}>
        {admin.name}
      </option>
    ));
  }, [admins]);

  const selectorBodyBroker = useMemo(() => {
    return currantBrokers?.map((admin) => (
      <option value={admin.id} key={admin.id}>
        {admin.name}
      </option>
    ));
  }, [currantBrokers]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !selectedSegments.crypto &&
      !selectedSegments.forex &&
      !selectedSegments.equity
    ) {
      setCheckboxError(t("At least one segment must be selected"));
      return;
    }

    const handleUpdateOrAdd = async () => {
      const values = Object.fromEntries(
        Object.entries(ib).map(([key, { value }]) => [key, value])
      );

      for (let key in ib) {
        const validationError = ValidateInputFields(
          key,
          ib[key].value,
          ib[key].required
        );
        setIbError((prevError) => ({
          ...prevError,
          [key]: validationError,
        }));
        if (validationError) return;
      }

      // return;
      const mutationResult = isEdit
        ? await updateIb({ id: data.id, ...values, ...selectedSegments })
        : await addIb({ ...values, ...selectedSegments });

      handleToast(t, mutationResult);
      if (mutationResult?.data?.status) {
        const tabIndex = isNew ? 1 : 0;
        refetch();
        getNewUser(mutationResult.data.data, tabIndex);
      }
    };

    handleUpdateOrAdd();
  };

  return (
    <div>
      <form>
        <div className="form-group">
          <label className="fw-bold">{t("Name")}*</label>
          <input
            type="text"
            className="form-control"
            placeholder={t("Enter Name")}
            name="name"
            onChange={handleChange}
            value={ib.name.value}
          />
          <span style={{ color: "red" }}>{t(ibError?.name)}</span>
        </div>
        <div className="row">
          {!isEdit && (
            <div className="form-group col-md-6">
              <label className="fw-bold">{t("Email")}*</label>
              <input
                type="text"
                className="form-control"
                placeholder={t("Enter Email")}
                name="email"
                onChange={handleChange}
                value={ib.email.value}
                autoComplete="off"
              />
              <span style={{ color: "red" }}>{t(ibError?.email)}</span>
            </div>
          )}
          <div className="form-group col-md-6">
            <label className="fw-bold">{t("Phone Number")}*</label>
            {/* <input
              type="tel"
              className="form-control"
              placeholder={t("Enter Phone )}Number"
              name="mobile_number"
              onChange={handleChange}
              value={ib.mobile_number.value}
            /> */}
            <PhoneInput
              key={"phoneInput"}
              country={"us"}
              value={ib.mobile_number.value}
              onChange={(phone) => {
                setIb((prev) => {
                  return {
                    ...prev,
                    mobile_number: { ...prev["mobile_number"], value: phone },
                  };
                });
                const validationError = ValidateInputFields(
                  "mobile_number",
                  phone,
                  ib["mobile_number"].required
                );
                setIbError((prevError) => {
                  return { ...prevError, mobile_number: validationError };
                });
              }}
              className=""
              placeholder={t("Email") + "/" + t("Mobile")}
              enableSearch={true}
              containerClass="baseclass"
              inputClass="somebaseclass"
              countryCodeEditable={false}
              name="mobile_number"
              inputProps={{
                autoFocus: true,
              }}
              // disabled={disableGetCode}
            />
            <span style={{ color: "red" }}>{t(ibError?.mobile_number)}</span>
          </div>
          {userType === "Global Admin" && (
            <div className="form-group col-md-6">
              <label className="fw-bold">{t("Select Admin")}*</label>
              <select
                className="form-select shadow-none  "
                id="planets-selector"
                name="adminId"
                value={ib.adminId?.value}
                onChange={handleChange}
                disabled={isEdit}
              >
                <option value="">{t("Select admin")}</option>
                {selectorBody}
              </select>
              <span style={{ color: "red" }}>{t(ibError?.adminId)}</span>
            </div>
          )}
          {userType !== "Broker" && (
            <div className="form-group col-md-6">
              <label className="fw-bold">{t("Select Broker")}*</label>
              <select
                className="form-select shadow-none  "
                id="planets-selector"
                name="brokerId"
                value={ib.brokerId?.value}
                onChange={handleChange}
                disabled={isEdit}
              >
                <option value="">{t("Select broker")}</option>
                {selectorBodyBroker}
              </select>
              <span style={{ color: "red" }}>{t(ibError?.brokerId)}</span>
            </div>
          )}

          {!isEdit && (
            <div className="form-group col-md-6">
              <label className="fw-bold">{t("Password")}* </label>
              <input
                type="password"
                className="form-control"
                placeholder={t("Enter Password")}
                name="password"
                onChange={handleChange}
                value={ib.password.value}
                autoComplete="off"
              />
              <span style={{ color: "red" }}>{t(ibError?.password)}</span>
            </div>
          )}
        </div>
        <div className="form-group">
          <label className="fw-bold">{t("Select Segments")}*</label>
          <div className="row">
            <div className="col-4">
              <label>
                <input
                  type="checkbox"
                  name="crypto"
                  checked={selectedSegments.crypto}
                  onChange={handleCheckboxChange}
                />
                <span style={{ paddingLeft: "10px" }}>{t("Crypto")}</span>
              </label>
            </div>
            <div className="col-4">
              <label style={{ paddingRight: "10px" }}>
                <input
                  type="checkbox"
                  name="forex"
                  checked={selectedSegments.forex}
                  onChange={handleCheckboxChange}
                />
                <span style={{ paddingLeft: "10px" }}>{t("Fx")}</span>
              </label>
            </div>
            <div className="col-4">
              <label style={{ paddingRight: "10px" }}>
                <input
                  type="checkbox"
                  name="equity"
                  checked={selectedSegments.equity}
                  onChange={handleCheckboxChange}
                />
                <span style={{ paddingLeft: "10px" }}>{t("Equity")}</span>
              </label>
            </div>
          </div>
          {checkboxError && (
            <span style={{ color: "red" }}>{t(checkboxError)}</span>
          )}
        </div>
        <Button
          className="btn btn1"
          variant={"primary"}
          isLoading={isEdit ? updatingIb : addingIb}
          onClick={handleSubmit}
        >
          {isEdit ? t("Update") : t("Submit")}
        </Button>
      </form>
    </div>
  );
};

export default Profile;
