// ProtectedRoute.js

import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = () => {
  const { user, permissions } = useSelector((state) => state.auth);

  return user ? (
    <Outlet hello={permissions} />
  ) : (
    <Navigate to="/login" replace />
  );
};

export default ProtectedRoute;
