import { apiSlice } from "./api";
import {
  postDataProviderApi,
  getDataProviderApi,
  updateDataProviderApi,
  deleteDataProviderApi,
  disableDataProviderApi,
} from "../components/constant/Api";

export const dataProviderApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addDataProvider: builder.mutation({
      query: (post) => ({
        url: postDataProviderApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["data-provider"],
    }),
    updateDataProvider: builder.mutation({
      query: (post) => ({
        url: updateDataProviderApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["data-provider"],
    }),

    deleteDataProvider: builder.mutation({
      query: (post) => ({
        url: deleteDataProviderApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["data-provider"],
    }),

    disableDataProvider: builder.mutation({
      query: (post) => ({
        url: disableDataProviderApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["data-provider"],
    }),
    getDataProvider: builder.query({
      query: () => ({
        url: getDataProviderApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
  }),
});

export const {
  useAddDataProviderMutation,
  useGetDataProviderQuery,
  useDeleteDataProviderMutation,
  useDisableDataProviderMutation,
  useUpdateDataProviderMutation,
} = dataProviderApi;
