import { apiSlice } from "./api";
import {
  getCryptoPairsApi,
  postCryptoPairsApi,
  updateCryptoPairsApi,
  deleteCryptoPairsApi,
} from "../components/constant/Api";

export const cryptoPairsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCryptoPairs: builder.query({
      query: () => ({
        url: getCryptoPairsApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
    addCryptoPairs: builder.mutation({
      query: (post) => ({
        url: postCryptoPairsApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["cryptoPairs"],
    }),
    updateCryptoPairs: builder.mutation({
      query: (post) => ({
        url: updateCryptoPairsApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["cryptoPairs"],
    }),
    deleteCryptoPairs: builder.mutation({
      query: (post) => ({
        url: deleteCryptoPairsApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["cryptoPairs"],
    }),
  }),
});

export const {
  useGetCryptoPairsQuery,
  useAddCryptoPairsMutation,
  useUpdateCryptoPairsMutation,
  useDeleteCryptoPairsMutation,
} = cryptoPairsApi;
