import React, { useEffect, useState } from "react";
import Button from "../../widgets/Button";
import { ImageValid } from "../../validations/ImageValid";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
const ReplyBox = ({
  message,
  isLoading,
  setMessage,
  file,
  setFile,
  adminReply,
  handleChange,
  error,
}) => {
  const { t } = useTranslation();
  const [imagePreview, setImagePreview] = useState(null);
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    e.target.value = null;
    if (!selectedFile) {
      setFile(null);
      setImagePreview(null);
      return;
    }
    const error = ImageValid(selectedFile);

    if (error) {
      toast.dismiss();
      toast.error(t(error));
      return;
    }

    setFile(selectedFile);

    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(selectedFile);
  };

  const handleRemoveImage = () => {
    setFile(null);
    setImagePreview(null);
  };

  useEffect(() => {
    if (!file) {
      setImagePreview(null);
    }
  }, [file]);

  return (
    <div className="textarea_chatting">
      <form onSubmit={adminReply} noValidate id="update-user">
        <div className="foot">
          <textarea
            value={message}
            onChange={handleChange}
            placeholder={t("Enter message")}
            className="form-control form_control_textarea"
            name="message"
          />
          <span className="text-danger">{t(error.message)}</span>
        </div>

        <div className="input_uploader">
          <i className="fa fa-paperclip"></i>

          <input
            type="file"
            accept="image/*"
            name="issueImage"
            onChange={handleFileChange}
          />
        </div>

        {imagePreview && (
          <div className="image-preview position-relative">
            <img
              style={{ maxWidth: "300px" }}
              src={imagePreview}
              alt="Preview"
            />
            <Button
              className="btn btn "
              variant="danger"
              onClick={handleRemoveImage}
            >
              X
            </Button>
          </div>
        )}
        <div className="text-right mt-2">
          <Button isLoading={isLoading} className="btn btn1" type="submit">
            <i className="fa fa-paper-plane"></i>
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ReplyBox;
