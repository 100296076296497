import React, { useState } from "react";
import {
  useAddCryptoMutation,
  useGetCryptosQuery,
  useUpdateCryptoMutation,
  useDeleteCryptoMutation,
} from "../../../redux/cryptoApi";
import TableLoader from "../../widgets/TableLoader";
import CryptoTable from "./CryptoTable";
import { ValidateInputFields } from "../../validations/ValidateInputFields";
import Button from "../../widgets/Button";
import CustomModal from "../../widgets/Modal";
import { ImageValid } from "../../validations/ImageValid";
import { baseUrl } from "../../constant/BaseUrl";
import { createFormDataFromObject } from "../../utils/formData";
import { handleToast } from "../../utils/handleToast";
import { useSelector } from "react-redux";
import PageLoader from "../../widgets/PageLoader";
import { useTranslation } from "react-i18next";
const url = `${baseUrl.replace("/api-admin/v1", "")}`;
const defaultCryptoState = {
  name: { value: "", required: true },
  symbol: { value: "", required: true },
  contractAddress: { value: "", required: false },
  currencyImage: { value: "", required: true },
  isDeposit: { value: "0", required: true },
  isWithdrawal: { value: "0", required: true },
  isTrade: { value: "0", required: true },
  isShow: { value: "0", required: true },
  isTab: { value: "0", required: true },
};

const defaultCryptoError = {
  name: "",
  symbol: "",
  contractAddress: "",
  currencyImage: "",
  isDeposit: "",
  isWithdrawal: "",
  isTrade: "",
  isShow: "",
  isTab: "",
};

const Crypto = () => {
  const { t } = useTranslation();
  const {
    permissions: { crypto: cryptoAccess },
  } = useSelector((state) => state.auth);

  const { data: records, isLoading, refetch } = useGetCryptosQuery();
  const [addCrypto, { isLoading: addingCrypto }] = useAddCryptoMutation();
  const [deleteCrypto, { isLoading: deletingCrypto }] =
    useDeleteCryptoMutation();
  const [updateCrypto, { isLoading: updatingCrypto }] =
    useUpdateCryptoMutation();
  const [editData, setEditData] = useState(null);
  const [show, setShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [crypto, setCrypto] = useState(defaultCryptoState);
  const [cryptoError, setCryptoError] = useState(defaultCryptoError);
  const [imagePreview, setImagePreview] = useState(null);
  const handleClose = () => {
    setCrypto(defaultCryptoState);
    setCryptoError(defaultCryptoError);
    setEditData(null);
    setIsEdit(false);
    setShow(false);
    setIsDelete(false);
    setImagePreview(null);
  };

  const handleShow = () => setShow(true);

  const handleEdit = (data) => {
    setEditData(data);
    setIsEdit(true);
    setImagePreview(
      data.icon ? `${url + "/static/currencyImage/" + data.icon}` : ""
    );
    setCrypto({
      name: { value: data.name, required: true },
      symbol: { value: data.symbol, required: true },
      contractAddress: { value: data.contract_address, required: false },
      currencyImage: { value: `${data.icon}`, required: true },
      isDeposit: { value: `${data.is_deposit}`, required: true },
      isWithdrawal: { value: `${data.is_withdrawal}`, required: true },
      isTrade: { value: `${data.is_trade}`, required: true },
      isShow: { value: `${data.is_show}`, required: true },
      isTab: { value: `${data.is_tab}`, required: true },
    });
    handleShow();
  };

  const handleDelete = (data) => {
    setDeleteData(data);
    setIsDelete(true);
    handleShow();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCrypto((prev) => {
      return { ...prev, [name]: { ...prev[name], value } };
    });

    const validationError = ValidateInputFields(
      name,
      value,
      crypto[name].required
    );

    setCryptoError((prevError) => {
      return { ...prevError, [name]: validationError };
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const error = ImageValid(file);

    if (error) {
      setCryptoError((prevError) => ({
        ...prevError,
        currencyImage: error,
      }));
      return;
    }

    setCrypto((prevCrypto) => ({
      ...prevCrypto,
      currencyImage: { value: file, required: true },
    }));

    const reader = new FileReader();

    reader.onloadend = () => {
      setImagePreview(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
      setCryptoError((prevError) => ({
        ...prevError,
        currencyImage: "",
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const handleDelete = async () => {
      const { id } = deleteData;

      const result = await deleteCrypto({ id });

      handleToast(t,result);
      if (result?.data?.status) {
        refetch();
        handleClose();
      }
    };

    const handleUpdateOrAdd = async () => {
      const values = Object.fromEntries(
        Object.entries(crypto).map(([key, { value }]) => [key, value])
      );

      for (let key in crypto) {
        const validationError = ValidateInputFields(
          key,
          crypto[key].value,
          crypto[key].required
        );
        setCryptoError((prevError) => ({
          ...prevError,
          [key]: validationError,
        }));
        if (validationError) return;
      }

      const formData = isEdit
        ? createFormDataFromObject({ id: editData.id, ...values })
        : createFormDataFromObject(values);

      const mutationResult = isEdit
        ? await updateCrypto(formData)
        : await addCrypto(formData);

      handleToast(t,mutationResult);
      if (mutationResult?.data?.status) {
        refetch();
        handleClose();
      }
    };

    isDelete ? handleDelete() : handleUpdateOrAdd();
  };

  if (!cryptoAccess?.permissions) {
    return <PageLoader />;
  }

  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="d-md-flex mb-3">
            <h3 className="page-title">{t("Coins")}</h3>
            <div className=" ms-auto">
              {cryptoAccess?.permissions?.coins?.write && (
                <button
                  disabled={addingCrypto}
                  className="btn btn1"
                  onClick={handleShow}
                >
                  {t('Add Coin')}
                </button>
              )}
            </div>
          </div>

          {isLoading ? (
            <TableLoader />
          ) : (
            <div className="white-box">
              <div className="table-responsive">
                <CryptoTable
                  data={records}
                  onEdit={handleEdit}
                  onDelete={handleDelete}
                  access={cryptoAccess?.permissions.coins}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <CustomModal
        isOpen={show}
        onClose={handleClose}
        title={
          isDelete ? t("Delete Crypto") : isEdit ? t("Edit Crypto") : t("Add Crypto")
        }
        footer={
          <Button
            className="btn btn1"
            variant={isDelete ? "danger" : "primary"}
            isLoading={
              isDelete ? deletingCrypto : isEdit ? updatingCrypto : addingCrypto
            }
            onClick={handleSubmit}
          >
            {isDelete ? t("Delete") : isEdit ? t("Update") : t("Submit")}
          </Button>
        }
      >
        {isDelete ? (
          <div>{t('Are you sure you want to delete?')}</div>
        ) : (
          <form>
            <div className="row">
              <div className="form-group col-md-6">
                <label className="fw-bold">{t('Name')}*</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t('Enter Name')}
                  name="name"
                  onChange={handleChange}
                  value={crypto.name.value}
                  disabled={isEdit}
                />
                <span style={{ color: "red" }}>{t(cryptoError?.name)}</span>
              </div>
              <div className="form-group col-md-6">
                <label className="fw-bold">{t('Symbol')}*</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t('Enter Symbol')}
                  name="symbol"
                  onChange={handleChange}
                  value={crypto.symbol.value}
                  disabled={isEdit}
                />
                <span style={{ color: "red" }}>{t(cryptoError?.symbol)}</span>
              </div>
              <div className="form-group col-md-6">
                <label className="fw-bold">{t('Contract Address')}</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t('Enter Contract Address')}
                  name="contractAddress"
                  onChange={handleChange}
                  value={crypto.contractAddress?.value}
                />
                <span style={{ color: "red" }}>
                  {t(cryptoError?.contractAddress)}
                </span>
              </div>
              <div className="form-group col-md-6">
                <label className="fw-bold">{t('Currency Image')}*</label>
                <input
                  type="file"
                  className="form-control"
                  accept="image/*"
                  name="currencyImage"
                  // value={crypto.currencyImage?.value}
                  onChange={handleImageChange}
                />
                {imagePreview && (
                  <img
                    src={imagePreview}
                    alt="Currency"
                    className="input_upload_img"
                  />
                )}
                <span style={{ color: "red" }}>
                  {t(cryptoError?.currencyImage)}
                </span>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6" style={{ display: "none" }}>
                <label className="fw-bold">{t('Deposit')}</label>
                <div className="d-flex align-items=-center">
                  <input
                    className="me-1"
                    type="radio"
                    name="isDeposit"
                    id="deposit"
                    value="1"
                    onChange={handleChange}
                    checked={crypto.isDeposit.value === "1"}
                  />
                  <label htmlFor="deposit">{t('Enabled')}</label>
                  <input
                    className="ms-3 me-1"
                    type="radio"
                    id="deposit2"
                    name="isDeposit"
                    value="0"
                    onChange={handleChange}
                    checked={crypto.isDeposit.value === "0"}
                  />
                  <label htmlFor="deposit2">{t('Suspended')}</label>
                </div>
                <span style={{ color: "red" }}>{t(cryptoError?.isDeposit)}</span>
              </div>
              <div className="form-group col-md-6" style={{ display: "none" }}>
                <label className="fw-bold">{t('Withdrawal')}</label>
                <div className="d-flex align-items=-center">
                  <input
                    className=" me-1"
                    type="radio"
                    id="withdrawal"
                    name="isWithdrawal"
                    value="1"
                    onChange={handleChange}
                    checked={crypto.isWithdrawal.value === "1"}
                  />
                  <label className="m-0" htmlFor="withdrawal">
                  {t('Enabled')}
                  </label>
                  <input
                    className="ms-3 me-1"
                    type="radio"
                    name="isWithdrawal"
                    id="withdrawal2"
                    value="0"
                    onChange={handleChange}
                    checked={crypto.isWithdrawal.value === "0"}
                  />
                  <label className="m-0" htmlFor="withdrawal2">
                  {t('Suspended')}
                  </label>
                </div>
                <span style={{ color: "red" }}>
                  {t(cryptoError?.isWithdrawal)}
                </span>
              </div>
              <div className="form-group col-md-6">
                <label className="fw-bold">{t('Trade')}</label>
                <div className="d-flex align-items=-center">
                  <input
                    className=" me-1"
                    type="radio"
                    name="isTrade"
                    id="trade"
                    value="1"
                    onChange={handleChange}
                    checked={crypto.isTrade.value === "1"}
                  />
                  <label htmlFor="trade">{t("Enabled")}</label>
                  <input
                    className="ms-3 me-1"
                    type="radio"
                    name="isTrade"
                    id="trade2"
                    value="0"
                    onChange={handleChange}
                    checked={crypto.isTrade.value === "0"}
                  />
                  <label htmlFor="trade2">{t('Suspended')}</label>
                </div>
                <span style={{ color: "red" }}>{t(cryptoError?.isTrade)}</span>
              </div>
              <div className="form-group col-md-6">
                <label className="fw-bold">{t('Is Show')}</label>
                <div className="d-flex align-items=-center">
                  <input
                    className=" me-1"
                    type="radio"
                    name="isShow"
                    id="show"
                    value="1"
                    onChange={handleChange}
                    checked={crypto.isShow.value === "1"}
                  />
                  <label htmlFor="show">{t('Enabled')}</label>
                  <input
                    className="ms-3 me-1"
                    type="radio"
                    name="isShow"
                    value="0"
                    id="show2"
                    onChange={handleChange}
                    checked={crypto.isShow.value === "0"}
                  />
                  <label htmlFor="show2">{t('Suspended')}</label>
                </div>
                <span style={{ color: "red" }}>{t(cryptoError?.isShow)}</span>
              </div>
              <div className="form-group col-md-6">
                <label className="fw-bold">{t('Is Tab')}</label>
                <div className="d-flex align-items=-center">
                  {/* Radio buttons for isTab */}
                  <input
                    className="me-1"
                    type="radio"
                    name="isTab"
                    id="tab"
                    value="1"
                    onChange={handleChange}
                    checked={crypto.isTab.value === "1"}
                  />
                  <label htmlFor="tab">{t('Enabled')}</label>
                  <input
                    className="ms-3 me-1"
                    type="radio"
                    name="isTab"
                    id="tab2"
                    value="0"
                    onChange={handleChange}
                    checked={crypto.isTab.value === "0"}
                  />
                  <label htmlFor="tab2">{t('Suspended')}</label>
                </div>
                <span style={{ color: "red" }}>{t(cryptoError?.isTab)}</span>
              </div>
            </div>
          </form>
        )}
      </CustomModal>
    </div>
  );
};

export default Crypto;
