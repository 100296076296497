import React, { useState, useEffect, useMemo } from "react";
import Select from "react-select";
import { useGetCryptoPairsQuery } from "../../../redux/cryptoPairsApi";
import { useGetFxPairsQuery } from "../../../redux/fxApi";
import TableLoader from "../../widgets/TableLoader";
import toast from "react-hot-toast";
import {
  useAssetPermissionMutation,
  useGetAssetPairsQuery,
} from "../../../redux/usersApi";
import { useGetCommissionFxQuery } from "../../../redux/commissionApi"
import { useTranslation } from "react-i18next";;

export const Fx = ({ editData, selectChange }) => {
  const { t } = useTranslation();
  const [selectedPairs, setSelectedPairs] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [currantPairs, setCurrantPairs] = useState([]);
  const {
    data: pairsData,
    isLoading: pairsLoading,
    refetch: refetchPairs,
  } = useGetAssetPairsQuery({ userId: editData.id, type: "fx" });
  const { data: records, isLoading } = useGetFxPairsQuery();
  const [updateAssetPermissions, { isLoading: updatingAssets }] =
    useAssetPermissionMutation();

  const {
    data: userCommissions = [],
    isLoading: commissionLoading,
    refetch: refetchUserCommissions,
    isFetching,
  } = useGetCommissionFxQuery({
    userId: editData.refred_by_id,
  });

  console.log(userCommissions, "userCommissionsFX");

  useEffect(() => {
    const createKeyValuePair = (pairs) => {
      return pairs?.map((pair) => ({
        value: pair.pair_id,
        label: `${pair.symbol?.toUpperCase()}}`,
      }));
    };
    if (pairsData && editData) {
      setCurrantPairs(pairsData);
      const pairValues = createKeyValuePair(pairsData);
      console.log(pairValues, "pairValues9633333");
      selectChange({
        selectedPairs: pairValues,
        type: "fx",
        fn: refetchPairs,
      });
      setSelectedPairs(pairValues);
      setIsShow(true);
    }
  }, [editData, pairsData]);

  // const options = useMemo(() => {
  //   return records?.map((cryptoPair) => ({
  //     value: cryptoPair.id,
  //     label: `${cryptoPair.symbol}`,
  //   }));
  // }, [records]);

  const options = useMemo(() => {
    return userCommissions?.map((cryptoPair) => ({
      value: cryptoPair.pairId,
      label: t(`${cryptoPair.symbol}`),
    }));
  }, [userCommissions]);

  const selectedOptions = useMemo(() => {
    return currantPairs?.map((pair) => ({
      value: pair.pair_id,
      label: t(`${pair.symbol?.toUpperCase()}`),
    }));
  }, [currantPairs]);

  if (!pairsData && !records) {
    return <TableLoader />;
  }
  return (
    <>
      {isLoading && pairsLoading ? (
        <TableLoader />
      ) : (
        <div>
          <h4>{t('Select Fx Pairs')}</h4>
          {!isShow ? (
            <TableLoader />
          ) : (
            <Select
              defaultValue={selectedOptions}
              isMulti
              name="colors"
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
              isLoading={isLoading}
              onChange={(selected) => {
                setSelectedPairs(selected);
                selectChange({
                  selectedPairs: selected,
                  type: "fx",
                  fn: refetchPairs,
                });
              }}
            />
          )}
        </div>
      )}
    </>
  );
};
