import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Fx, Equity, Crypto } from "../AssetBlock";
import toast from "react-hot-toast";
import Button from "../../widgets/Button";
import { useAssetBlockMutation } from "../../../redux/usersApi";
import { useTranslation } from "react-i18next";

const AssetBlockTabs = ({ editData, isEdit }) => {
  const { t } = useTranslation();
  const [assetData, setAssetData] = useState(null);
  const [updateAssetBlock, { isLoading: updatingAssets }] =
    useAssetBlockMutation();

  const selectChange = (data) => {
    setAssetData(data);
  };

  const handleBlockTabsSubmit = async () => {
    const values = assetData.selectedPairs.map((coinId) => coinId.value);
    console.log({
      coinIds: values,
      userId: editData.id,
      type: assetData.type,
    });

    const result = await updateAssetBlock({
      coinIds: values,
      userId: editData.id,
      type: assetData.type,
    });
    toast.dismiss();
    if (result?.data?.status) {
      toast.success(t(result.data.message));
      assetData.fn();
    } else {
      toast.error(t(result?.data?.message) || t("Error occurred"));
    }
  };
  return (
    <Tabs>
      <TabList>
        <Tab>{t("Crypto")}</Tab>
        <Tab>{t("Fx")}</Tab>
        <Tab>{t("Equity")}</Tab>
      </TabList>
      <TabPanel>
        <Crypto editData={editData} selectChange={selectChange} />
      </TabPanel>
      <TabPanel>
        <Fx editData={editData} selectChange={selectChange} />
      </TabPanel>
      <TabPanel>
        <Equity editData={editData} selectChange={selectChange} />
      </TabPanel>
      <Button
        className="btn btn1 mt-2"
        variant={"primary"}
        isLoading={updatingAssets}
        onClick={handleBlockTabsSubmit}
      >
        {t("Save")}
      </Button>
    </Tabs>
  );
};

export default AssetBlockTabs;
