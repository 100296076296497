import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import SpreadCrypto from "./SpreadCrypto";
import { useTranslation } from "react-i18next";
const SpreadTabs = ({
  isEdit,
  editData,
  tabIndex: editIndex,
  handleClose,
  isDelete,
}) => {
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(editIndex || 0);

  return (
    <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
      <TabList>
        <Tab>{t("Crypto")}</Tab>
        {/* <Tab disabled>Equity</Tab> */}
        {/* <Tab disabled>Fx Trade</Tab> */}
      </TabList>

      <TabPanel>
        <SpreadCrypto
          isEdit={isEdit}
          editData={editData}
          isDelete={isDelete}
          handleClose={handleClose}
        />
      </TabPanel>
      <TabPanel>
        <h4>{t("Any content 2")}</h4>
      </TabPanel>
      <TabPanel>
        <h4>{t("Any content 3")}</h4>
      </TabPanel>
    </Tabs>
  );
};

export default SpreadTabs;
