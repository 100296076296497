import { apiSlice } from "./api";
import {
  getAdminApi,
  postAdminApi,
  updateAdminApi,
  deleteAdminApi,
  getAdminPermissions,
} from "../components/constant/Api";

export const adminApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAdmins: builder.query({
      query: () => ({
        url: getAdminApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
    addAdmin: builder.mutation({
      query: (post) => ({
        url: postAdminApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["admin"],
    }),
    updateAdmin: builder.mutation({
      query: (post) => ({
        url: updateAdminApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["admin"],
    }),
    deleteAdmin: builder.mutation({
      query: (post) => ({
        url: deleteAdminApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["admin"],
    }),
  }),
});

export const {
  useGetAdminsQuery,
  useAddAdminMutation,
  useDeleteAdminMutation,
  useUpdateAdminMutation,
} = adminApi;
