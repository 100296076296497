import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { logIn } from "../services/Login";
import { LoginValid } from "../validations/LoginValid";
import toast from "react-hot-toast";
import { useGetUserPermissionsMutation } from "../../redux/usersApi";
import { useDispatch, useSelector } from "react-redux";
import { setCredentials } from "../../redux/authSlice";

const Login = () => {
  const dispatch = useDispatch();
  const { user, isAuthenticated } = useSelector((state) => state.auth);
  const [getPermissions] = useGetUserPermissionsMutation();
  const navigate = useNavigate();
  const [loginField, setLoginField] = useState({ email: "", password: "" });
  const [loginFieldErr, setLoginFieldErr] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (isAuthenticated && user) {
      navigate("/dashboard");
    }
  }, [navigate, user, isAuthenticated]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginField({ ...loginField, [name]: value });
    let checkLogin = LoginValid(name, value);
    setLoginFieldErr({ ...loginFieldErr, [name]: checkLogin });
  };

  const onLogin = async (event) => {
    event.preventDefault();
    for (let key in loginField) {
      const checkLogin = LoginValid(key, loginField[key]);
      setLoginFieldErr({ ...loginFieldErr, [key]: checkLogin });
      if (checkLogin !== "") return false;
    }
    const LoginData = {
      email: loginField.email,
      password: loginField.password,
    };
    const result = await logIn(LoginData);

    if (result.status) {
      const { token } = result;
      localStorage.setItem("jwtToken", token);
      const userPermissions = await getPermissions().unwrap();

      if (userPermissions) {
        dispatch(setCredentials(userPermissions));
        toast.success(result.message);
        navigate("/dashboard");
        // setTimeout(() => navigate("/dashboard", { replace: true }), 2000);
      }
    } else {
      toast.dismiss();
      toast.error(result.message);
    }
  };

  return (
    <div>
      <main
        className="d-flex align-items-center min-vh-100 py-3 py-md-0 main_bg"
        style={{
          background: "url(assets/img/bg.jpg) no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-10 m-auto">
              <div className="bg2 login-card">
                <div className="row no-gutters">
                  <div className="col-md-6 position-relative">
                    <img
                      src="assets/img/login.jpg"
                      alt="login"
                      className="login-card-img"
                    />
                  </div>
                  <div className="col-md-6">
                    <div className="card-body">
                      <div className="brand-wrapper mb-4">
                        <img
                          src="assets/img/logo.png"
                          alt="logo"
                          className="logo"
                        />
                      </div>
                      <p className="login-card-description">
                        Sign into your account
                      </p>
                      <form action="#!">
                        <div className="form-group">
                          <label htmlFor="email" className="sr-only">
                            Email
                          </label>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            className="form-control"
                            placeholder="Email address"
                            onChange={handleChange}
                            value={loginField.email}
                          />
                          <span className="text-danger">
                            {loginFieldErr.email}
                          </span>
                        </div>
                        <div className="form-group mb-4">
                          <label htmlFor="password" className="sr-only">
                            Password
                          </label>
                          <input
                            type="password"
                            name="password"
                            id="password"
                            className="form-control"
                            placeholder="***********"
                            onChange={handleChange}
                            value={loginField.password}
                          />
                          <span className="text-danger">
                            {loginFieldErr.password}
                          </span>
                        </div>
                        <button
                          type="submit"
                          className="btn btn1 btn-block login-btn mb-4 w-100 p-2"
                          onClick={onLogin}
                        >
                          Log In
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
export default Login;
